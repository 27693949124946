import { Component, EventEmitter, Input, KeyValueDiffer, KeyValueDiffers, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

import { environment } from "../../../../../environments/environment";
import { AgendamentoDescricao } from "../../../common/libs/agendamento-descricao/agendamento-descricao";
import { DefaultValuesService } from "../../../common/service/default-values.service";
import { generateHours, generateHoursNew, generateMinutes, isNullOrEmpty } from "../../../common/utils";
import { AgendamentoModel } from "../../models/agendamento.model"

@Component({
    selector: "app-agendamento-estrategia",
    templateUrl: "./agendamento-estrategia.component.html",
    styleUrls: ["./agendamento-estrategia.component.scss"],
    providers: [
        { useClass: DefaultValuesService, provide: DefaultValuesService },
        { useClass: TranslateService, provide: TranslateService }
    ]
})
export class AgendamentoEstrategiaComponent {

    AppConfig: any;

    @Input()
    dirty: boolean = false;

    @Input()
    isNew: boolean;

    @Input()
    telaTranslateDescricao: string = '';

    // @Input()
    // horariosReservados: Array<any>;

    _horariosReservados: Array<any>;

    @Input()
    public set horariosReservados(value: Array<any>) {
        this._horariosReservados = value;
        this.obterHorariosDisponiveis();
    }

    public get horariosReservados() {
        return this._horariosReservados;
    }


    tiposEnvio: Array<any>;
    frequenciasEnvio: Array<any>;
    horariosCollection: Array<any>;

    //#region [ GETS ]

    public get erros(): any {
        if (!this.dirty) return;
        return this.agendamento.validar();
    }

    public get intervaloMinino(): number {
        return (this.agendamento.frequencia == '1') ? this.AppConfig.estrategiaAgendamentoMinimoMinutos : 1;
    }

    public get terminoDesabilitado(): boolean {
        if (this.agendamento.execucaoUnica || this.agendamento.comDataFim == false) return true;
        return false;
    }

    public get descricao(): string {
        return AgendamentoDescricao(this.agendamento, this.telaTranslateDescricao, this.translate);
    }

    //#endregion

    //#region [ GET/SET - Agendamento ]  

    private agendamentoDiferenca: KeyValueDiffer<string, any>;

    _agendamento: AgendamentoModel;

    @Input()
    public set agendamento(value: AgendamentoModel) {
        this._agendamento = value;

        if (this.agendamento.horaInicio) {
            let h = this.agendamento.horaInicio.split(":");
            this.agendamento.horaInicio = `${h[0]}:${h[1]}`;
        }

        if(!isNullOrEmpty(this.agendamento.frequencia))
            this.agendamento.frequencia = this.agendamento.frequencia.toString();

        this.agendamentoDiferenca = this.differs.find(this.agendamento).create();
    }

    public get agendamento() {
        return this._agendamento;
    }

    @Output()
    agendamentoChange = new EventEmitter<AgendamentoModel>();

    //#endregion

    constructor(
        private defaultValuesService: DefaultValuesService,
        private translate: TranslateService,
        private differs: KeyValueDiffers
    ) {
        this.AppConfig = environment;

        this.agendamento = new AgendamentoModel();
        this.agendamentoChange = new EventEmitter<AgendamentoModel>();

        if (this.isNew && this.dirty === false) {
            this.agendamento.execucaoRecorrente = false;
            this.agendamento.dataInicio = new Date();
        } else {
            if (this.agendamento.horaInicio) {
                let h = this.agendamento.horaInicio.split(":");
                this.agendamento.horaInicio = `${h[0]}:${h[1]}`;
            }
        }

        this.obterTiposEnvio();
        this.obterFrequenciasEnvio();
        this.obterHorariosDisponiveis();
    }

    //#region [ Eventos da página ]

    ngDoCheck(): void {
        const changes = this.agendamentoDiferenca.diff(this.agendamento);

        if (changes)
            this.agendamentoChange.emit(this.agendamento);
    }

    //#endregion

    //#region [ Carregamento da página ]

    obterTiposEnvio() {
        this.defaultValuesService
            .obterEstrategiaTipoEnvio()
            .then(tipos => (this.tiposEnvio = tipos));
    }

    obterFrequenciasEnvio() {
        this.defaultValuesService
            .obterFrequenciasEnvio()
            .then(frequencias => (this.frequenciasEnvio = frequencias));
    }

    //#endregion

    //#region [ Eventos de Change ]

    tipoEnvioChance(event: any) {

        if (this.agendamento.tipoEnvio == 1) {
            this.agendamento.execucaoUnica = true;
            this.agendamento.recorrente = false;
            return;
        }



        this.agendamento.execucaoUnica = (this.agendamento.tipoEnvio == 2);
        this.agendamento.recorrente = true;

        if (this.isNew && this.dirty === false) {
            let dataAtual = new Date();

            this.agendamento.dataInicio = dataAtual;
            this.proximaHora();

            // Execução recorrente
            if (this.agendamento.execucaoUnica == false) {
                this.agendamento.frequencia = '1';
                this.agendamento.intervalo = this.AppConfig.estrategiaAgendamentoMinimoMinutos;
                this.agendamento.diaSemana = null;
                this.agendamento.comDataFim = false;
                this.agendamento.dataTermino = null;
                this.agendamento.horaTermino = null;
            }
        } else {

            if (this.agendamento.execucaoUnica == false) {
                this.agendamento.frequencia = '1';
                this.agendamento.intervalo = this.AppConfig.estrategiaAgendamentoMinimoMinutos;
                this.agendamento.diaSemana = null;
                this.agendamento.comDataFim = false;
                this.agendamento.dataTermino = null;
                this.agendamento.horaTermino = null;
            } else {
                this.agendamento.intervalo = null;
            }

            this.proximaHora();
        }

    }

    frequenciaChange(event: any) {
        this.agendamento.dataInicio = new Date();
        this.agendamento.intervalo = (this.agendamento.frequencia == '1') ? this.AppConfig.estrategiaAgendamentoMinimoMinutos : 1;
        this.proximaHora();
    }

    //#endregion


    obterHorariosDisponiveis() {
        let horarios = generateHoursNew(this.AppConfig.estrategiaAgendamentoMinimoMinutos)

        if (!isNullOrEmpty(this.horariosReservados)) {
            this.horariosReservados
                //.map((item: any) => item.horaInicio.substring(0, 5))
                .forEach((item: any) => {
                    let index = horarios.indexOf(item);
                    if (index > -1)
                        horarios.splice(index, 1);
                });
        }

        this.horariosCollection = [];
        horarios.forEach((hora: any) => this.horariosCollection.push({ 'id': `${hora}`, 'valor': `${hora}` }));
        this.obterProximaHora();
    }

    obterProximaHora() {
        if (this.isNew && this.dirty === undefined)
            this.proximaHora();
    }

    proximaHora() {
        let atual = new Date();
        let minutoAtual = atual.getMinutes();
        let horaAtual = '';

        while (minutoAtual % this.AppConfig.estrategiaAgendamentoMinimoMinutos != 0) { minutoAtual++; }

        if (minutoAtual == 60) {
            atual.setMinutes(atual.getMinutes() + this.AppConfig.estrategiaAgendamentoMinimoMinutos);
            horaAtual = `${atual.getHours()}:00`;
            minutoAtual = 0
        } else {
            horaAtual = `${atual.getHours()}:${minutoAtual}`;
        }

        if (this.horariosCollection.some((item) => (item.id == horaAtual)))
            this.agendamento.horaInicio = horaAtual;
        else {
            let r = true;

            let horas = generateHours();
            let minutos = generateMinutes(this.AppConfig.estrategiaAgendamentoMinimoMinutos);

            horas.forEach((h: string) => {
                minutos.forEach((m: string) => {

                    let d1 = new Date(atual.getFullYear(), atual.getMonth(), atual.getDay(), parseInt(h), parseInt(m));
                    let d2 = new Date(atual.getFullYear(), atual.getMonth(), atual.getDay(), atual.getHours(), minutoAtual);
                    if (d1 >= d2 && r) {
                        if (this.horariosCollection.some((item: any) => (item.id == `${h}:${m}`))) {
                            r = false;
                            this.agendamento.horaInicio = `${h}:${m}`;
                        }
                    }
                });
            });
        }

        if (isNullOrEmpty(this.agendamento.horaInicio) && this.horariosCollection.length > 0)
            this.agendamento.horaInicio = this.horariosCollection[this.horariosCollection.length - 1];
    }

}
