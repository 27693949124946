
import { Subscription } from 'rxjs';
import { Component, OnDestroy, OnInit, Input, Output, EventEmitter, AfterViewInit, ViewChild, ElementRef, NgZone, KeyValueDiffer, KeyValueDiffers } from "@angular/core";
import { MatDialog } from '@angular/material';
import * as jquery from 'jquery';

import { ListaService } from "../../common/service/lista.service"
import { TemplateService } from "../../common/service/template.service";
import { ListaExpressaoService } from '../../common/service/lista-expressao.service';
import { NotificationService } from "../../common/service/notification.service";
import { ExpressaoRegex } from '../../common/model/expressao-regex';

import { ESBuilderData, ESBuilderRules, ESBuilderRulesConditions, ESBuilderRulesChange, ESBFiltroStatusRegistroLista, ESBuilderQuantityList } from "../../esbuilder/models";
import { QueryToModel, ModelToQuery, isObjectEmpty } from '../../esbuilder/libs/utils';
import { PreviewDadosEstrategiaComponent } from "../../estrategia/form-estrategia/preview-dados-estrategia/preview-dados-estrategia.component";
import { Lista } from '../../common/model/lista';
import { EsBuilderService } from '../../esbuilder/libs/services/esbuilder.service';
import { isNull, isNullOrEmpty, isNullOrZero } from '../../common/utils';
import { ElasticsearchRepositoryService } from '../../elasticsearch/repository/elasticsearch-repository.service';
import { generateCanvas } from '../../common/utils/generateCanvas';
import { FiltroQueryService } from '../../common/service/filtro-query.service';
import { FiltroQuery } from '../../common/model/filtro-query';
import { EstrategiaRefactor } from '../../common/model/estrategiaRefactor';
import { EstrategiaService } from '../../common/service/estrategia.service';
import { EstrategiaAcaoCampanhaTipo } from '../../common/model/situacao-execucao';
import sha256 from 'crypto-js/sha256';
import MD5 from 'crypto-js/md5';
import { FiltroQueryEstrategia } from '../../common/model/filtro-query-estrategia';


const $ = jquery;


@Component({
    selector: "app-filtro-query-padrao-aplicar",
    templateUrl: "./filtro-query-padrao-aplicar.component.html",
    styleUrls: ["./filtro-query-padrao-aplicar.component.scss"],
    providers: [
        { useClass: ListaService, provide: ListaService },
        { useClass: TemplateService, provide: TemplateService },
        { useClass: FiltroQueryService, provide: FiltroQueryService },
        { useClass: EstrategiaService, provide: EstrategiaService },
        //{ useClass: EsBuilderService, provide: EsBuilderService },
        { useClass: ElasticsearchRepositoryService, provide: ElasticsearchRepositoryService }
    ]
})
export class FiltroQueryPadraoAplicarComponent implements OnInit, OnDestroy {

    // estrategias: EstrategiaRefactor[] = [];
    // estrategiasSource: EstrategiaRefactor[] = [];
    // estrategiasTarget: EstrategiaRefactor[] = [];

    @Input()
    debug: boolean = false;

    //#region [ GET/SET - EstrategiasSource ]

    _estrategiasSource: FiltroQuery;

    public get estrategiasSource(): FiltroQuery {
        return this._estrategiasSource;
    }

    @Input()
    public set estrategiasSource(v: FiltroQuery) {
        this._estrategiasSource = v;
    }

    //#endregion 

    //#region [ GET/SET - EstrategiasTarget ]

    _estrategiasTarget: EstrategiaRefactor;

    public get estrategiasTarget(): EstrategiaRefactor {
        return this._estrategiasTarget;
    }

    @Input()
    public set estrategiasTarget(v: EstrategiaRefactor) {
        this._estrategiasTarget = v;
    }

    //#endregion 


    //#region [ GET/SET - FiltroQuery ]

    _filtroQuery: FiltroQuery;

    public get filtroQuery(): FiltroQuery {
        return this._filtroQuery;
    }

    @Input()
    public set filtroQuery(v: FiltroQuery) {
        this._filtroQuery = v;
    }

    //#endregion 

    //#region [ GET/SET - JsonViewObject ]

    jsonView: any = [];

    _jsonViewObject: string;

    public get jsonViewObject(): string {
        return this._jsonViewObject;
    }

    public set jsonViewObject(v: string) {

        if (isNullOrEmpty(v)) {
            this.jsonView = [];
            return;
        }

        this._jsonViewObject = v;

        if (!v.includes("[")) {
            this.jsonView = this[v];
            return;
        }

        let st = v.split('[').map((m: string) => (m.replace("[", "").replace("]", "")));

        switch (st.length - 1) {
            case 0:
                this.jsonView = this[st[0]];
                break;
            case 1:
                this.jsonView = this[st[0]][st[1]];
                break;
            case 2:
                this.jsonView = this[st[0]][st[1]][st[2]];
                break;
            case 3:
                this.jsonView = this[st[0]][st[1]][st[2]][st[3]];
                break;
        }
    }

    //#endregion 

    //subEstrategia: Subscription;

    public get isListaSelecionada(): boolean {
        return (!isNullOrZero(this.filtroQuery.listaId));
    }

    constructor(private zone: NgZone,
        public dialog: MatDialog,
        private estrategiaService: EstrategiaService,
        private filtroQueryService: FiltroQueryService,
        private notificationService: NotificationService,
        private esBuilderService: EsBuilderService,
        private differs: KeyValueDiffers) {
    }

    //#region [ Eventos do componente ]

    ngOnInit() { }

    ngOnDestroy() { }

    //#endregion

    onMoveToTarget(e: any) {
        let filter = FiltroQueryEstrategia.AdicionarFiltroPadrao(this.filtroQuery,  e.items[0]);
        e.items[0].filtroQueryId = null;
        e.items[0].filtro = filter.query;
        e.items[0].filtroRegras = filter.filtroRegras;
        e.items[0].filtroQueryEstrategias = filter.filtroQueryEstrategias
        e.items[0].filtroAlterado = (e.items[0].hashFiltroAntigo != MD5(e.items[0].filtro).toString());
    }

    onMoveAllToTarget(e: any) {
        e.items = e.items.map((m: EstrategiaRefactor) => {
            let filter = FiltroQueryEstrategia.AdicionarFiltroPadrao(this.filtroQuery,  m);
            e.items[0].filtroQueryId = null;
            e.items[0].filtro = filter.query;
            e.items[0].filtroRegras = filter.filtroRegras;
            e.items[0].filtroQueryEstrategias = filter.filtroQueryEstrategias
            m.filtroAlterado = (m.hashFiltroAntigo != MD5(m.filtro).toString());
            return m;
        });
    }

    onMoveToSource(e: any) {
        let filter = FiltroQueryEstrategia.RemoverFiltroPadrao(this.filtroQuery, e.items[0]);
        e.items[0].filtroQueryId = null;
        e.items[0].filtro = filter.query;
        e.items[0].filtroRegras = filter.filtroRegras;
        e.items[0].filtroQueryEstrategias = filter.filtroQueryEstrategias
        e.items[0].filtroAlterado = (e.items[0].hashFiltroAntigo != MD5(e.items[0].filtro).toString());
    }

    onMoveAllToSource(e: any) {
        e.items = e.items.map((m: EstrategiaRefactor) => {
            let filter = FiltroQueryEstrategia.RemoverFiltroPadrao(this.filtroQuery, m)
            e.items[0].filtroQueryId = null;
            e.items[0].filtro = filter.query;
            e.items[0].filtroRegras = filter.filtroRegras;
            e.items[0].filtroQueryEstrategias = filter.filtroQueryEstrategias
            m.filtroAlterado = (m.hashFiltroAntigo != MD5(m.filtro).toString());
            return m;
        });
    }
}