import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog, MatTabChangeEvent } from "@angular/material";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject, Operator, Subscription, forkJoin, Observable } from "rxjs";
import 'hammerjs';

import { environment } from "../../../../environments/environment";
import { isNullOrZero, isNull, isNullOrEmpty, isObjectEmpty } from "../../common/utils";

import { CustomAlertService } from "../../common/service/custom-alert.service";
import { DefaultValuesService } from '../../common/service/default-values.service';
import { EstrategiaService } from "../../common/service/estrategia.service";
import { CarteiraService } from "../../common/service/carteira.service";
import { TemplateService } from "../../common/service/template.service";
import { ListaService } from "../../common/service/lista.service";
import { CampanhaService } from "../../common/service/campanha.service";
import { ProviderService } from "../../common/service/provider.service";
import { PreferenciasUsuarioService } from "../../common/service/preferencias-usuario.service";

import { EstrategiaRefactor } from "../../common/model/estrategiaRefactor";
import { TipoEstrategia, EstrategiaAcaoCampanhaTipo } from "../../common/model/situacao-execucao";

import { Template } from "../../common/model/template";
import { Provider } from "../../common/model/provider";
import { IntegracaoTipo } from "../../common/model/provedor-configuracao";
import { FiltroStatusRegistroLista } from "../../common/model/lista";
import { FormEstrategiaListaSelecionada } from "../models/form-estrategia-lista-selecionada.model";
import { ESBFiltroStatusRegistroLista, ESBuilderData, ESBuilderRules } from "../../esbuilder/models";

import { AgendamentoEstrategiaComponent } from "./agendamento-estrategia/agendamento-estrategia.component";
import { OrdenacaoEstrategiaComponent } from '../ordenacao-estrategia/ordenacao-estrategia.component';
import { FormEstrategiaEstrategiaCampanhaBase } from '../models/form-estrategia-estrategia-campanha-base.mode';
import { AgendamentoModel } from '../models/agendamento.model';
import { Carteira } from '../../common/model/carteira';
import { PreferenciasUsuario } from '../../common/model/preferencias-usuario';
import Swal from 'sweetalert2';
import { ListaPrioridadePadrao } from '../../common/model/lista-prioridade-padrao';
import { Order } from '../models/order';
import { ListaPrioridadePadraodService } from '../../common/service/lista-prioridade-padrao.service';
import { FiltroQueryEstrategia } from '../../common/model/filtro-query-estrategia';


// import { FiltroQuery } from "../../common/model/filtro-query";
// import { PreviewDadosEstrategiaComponent } from "./preview-dados-estrategia/preview-dados-estrategia.component";
// import { ElasticsearchFilterComponent } from "../../elasticsearch/filter/elasticsearch-filter/elasticsearch-filter.component";
// import { FiltroQueryPadraoModalComponent } from "../../filtro-query-padrao/filtro-query-padrao-modal/filtro-query-padrao-modal.component";

@Component({
    selector: "app-form-estrategia",
    templateUrl: "./form-estrategia.component.html",
    styleUrls: ["./form-estrategia.component.scss"],
    providers: [
        { useClass: DefaultValuesService, provide: DefaultValuesService },
        { useClass: EstrategiaService, provide: EstrategiaService },
        { useClass: ListaService, provide: ListaService },
        { useClass: TemplateService, provide: TemplateService },
        { useClass: CampanhaService, provide: CampanhaService },
        { useClass: CustomAlertService, provide: CustomAlertService },
        { useClass: ProviderService, provide: ProviderService },
        { useClass: ListaPrioridadePadraodService, provide: ListaPrioridadePadraodService },
        { useClass: CarteiraService, provide: CarteiraService },
        { useClass: PreferenciasUsuarioService, provide: PreferenciasUsuarioService }
    ]
})

export class FormEstrategiaComponent implements OnInit {

    desabilitarEstrategiasRemocao: boolean = false;

    appConfig: any;
    paramsRoute: any;
    debug: boolean = false;
    edit: boolean = false;
    dirty: boolean = false;
    bloquearEscolhaLista: boolean = false;
    finishedLoading: boolean = false;
    tabSelected: number = 0;

    estrategia: EstrategiaRefactor;
    agendamento: AgendamentoModel;

    estrategiaAcaoCampanhaTipos: Array<any>;

    preferenciasUsuario: PreferenciasUsuario;

    carteiras: Array<Carteira>;
    carteiraSelecionada: number;

    listas: Array<FormEstrategiaListaSelecionada>;
    listaSelecionada: FormEstrategiaListaSelecionada;

    providers: Array<Provider>;
    providerSelecionado: Provider;

    templatesExportacao: Array<Template>;
    templatesExportacaoFiltrado: Array<Template>;
    templateExportacaoSelecionado: Template;

    campanhas: Array<any>;
    campanhasSelecionada: any;

    horariosReservados: Array<string>;

    query: any = JSON.parse('{"size":10,"_source":{"includes":[]},"query":{"bool":{"must":[]}}}');
    dataRules: ESBuilderData = new ESBuilderData();

    estrategiasCampanhaBase: Array<FormEstrategiaEstrategiaCampanhaBase>;
    estrategiasCampanhaBaseSelecionada: FormEstrategiaEstrategiaCampanhaBase;

    mostrarOrdernacao = false;

    listaPrioridades: ListaPrioridadePadrao[] = [];
    listaPrioridadesSelecionadas: ListaPrioridadePadrao[] = [];
    @ViewChild("ordenacao", { static: false })
    elasticsearchOrder: OrdenacaoEstrategiaComponent;

    //#region [ EnviarReutilizar ]

    public get enviarReutilizar() {
        return !this.estrategia.marcarDistribuido;
    }

    public set enviarReutilizar(reutilizar) {
        this.estrategia.marcarDistribuido = !reutilizar;
    }

    //#endregion

    //#region [ GETS ]

    public get rotaLista(): boolean {
        return (isNullOrZero(this.paramsRoute.listaId) == false);
    }

    public get listaNaoSelecionada() {
        return (isNullOrZero(this.estrategia.listaId));
    }

    public get provedorNaoSelecionado() {
        return (isNullOrZero(this.estrategia.listaId));
    }

    public get templateExportacaoNaoSelecionado() {
        return (isNullOrZero(this.estrategia.listaId));
    }

    public get desabilitarComboTemplate(): boolean {
        return (this.listaNaoSelecionada || this.provedorNaoSelecionado)
    }

    public get mostrarComboTemplateExportacao(): boolean {
        if (isNull(this.templatesExportacaoFiltrado))
            return false;

        return (this.templatesExportacaoFiltrado.length > 0)
    }

    public get desabilitarComboCampanha(): boolean {
        return (this.listaNaoSelecionada || this.templateExportacaoNaoSelecionado || this.providerSelecionado.campanha.layoutPorCampanha)
    }

    public get mostrarComboCampanha(): boolean {
        if (isNullOrZero(this.estrategia.providerId) || isNull(this.providerSelecionado))
            return false;

        return this.providerSelecionado.configuracaoPorCampanha;
    }

    public get estrategiaExportacao() {
        return (this.estrategia.estrategiaAcaoCampanhaTipoId == EstrategiaAcaoCampanhaTipo.Exportacao)
    }

    public get estrategiaRemocaoCampanha() {
        return (this.estrategia.estrategiaAcaoCampanhaTipoId == EstrategiaAcaoCampanhaTipo.RemocaoCampanhaEpbx)
    }

    public get estrategiaRemocaoTodasCampanhas() {
        return (this.estrategia.estrategiaAcaoCampanhaTipoId == EstrategiaAcaoCampanhaTipo.RemocaoTodasCampanhasEpbx)
    }

    public get campanhaBaseSelecionada() {
        if (isNull(this.estrategiasCampanhaBaseSelecionada))
            return '---';

        return `ID: ${this.estrategiasCampanhaBaseSelecionada.campanhaId} - ${this.estrategiasCampanhaBaseSelecionada.campanhaNome}`;
    }

    public get validarCampoCampanha() {
        if (isNull(this.providerSelecionado))
            return false;

        if (isNull(this.providerSelecionado.campanha))
            return false;

        return this.providerSelecionado.campanha.layoutPorCampanha;
    }


    public get filtrosQueryEstrategia(): number[] {
        if(isNull(this.estrategia.filtroQueryEstrategias))
            return [];
        else
            return this.estrategia.filtroQueryEstrategias.filter(item=> item.ativo).map(m=> m.filtroQueryId);
    }


    //#endregion

    //#region [ GETS - Erros ]

    public get erros() {
        if (!this.dirty) return;

        return this.estrategia.validar(this.validarCampoCampanha);
    }

    public get erroAgendamento() {
        return this.erros && (this.erros.intervalo || this.erros.horaInicio || this.erros.dataInicio || this.erros.dataTermino || this.erros.horaTermino)
    }

    //#endregion


    @ViewChild(OrdenacaoEstrategiaComponent, { static: false }) ordenacao: OrdenacaoEstrategiaComponent;
    constructor(
        private dialog: MatDialog,
        private location: Location,
        private route: ActivatedRoute,
        private router: Router,
        private customAlertService: CustomAlertService,
        private defaultValuesService: DefaultValuesService,
        private estrategiaService: EstrategiaService,
        private carteiraService: CarteiraService,
        private preferenciasService: PreferenciasUsuarioService,
        private listaPrioridadePadraodService: ListaPrioridadePadraodService,
        private listaService: ListaService,
        private providerService: ProviderService,
        private templateService: TemplateService
    ) {
        this.appConfig = environment;
        this.estrategia = new EstrategiaRefactor();
        this.agendamento = new AgendamentoModel();
        this.listas = [];
        this.listaSelecionada = new FormEstrategiaListaSelecionada();

        // Setando valores padrão para a criação de uma nova estratégia
        this.estrategia.estrategiaAcaoCampanhaTipoId = EstrategiaAcaoCampanhaTipo.Exportacao;
        this.estrategia.listaEstrategiaId = 0;
        this.setValoresPadraoEstrategiaExportacao();

        // Preferencias do usuário
        this.preferenciasUsuario = this.preferenciasService.localStorageObterPreferenciasUsuario();
        this.carteiraSelecionada = 0;


        this.route.queryParams.subscribe(params => {
            if (params.debug != undefined)
                this.debug = (params.debug === 'true');
        });
    }

    //#region [ Eventos da página ]	

    ngOnInit() {
        this.obterEstrategiaAcaoCampanhaTipos()

        this.route.params.subscribe(params => {
            this.paramsRoute = params;

            if (this.paramsRoute.id) {
                this.edit = true;
                this.obterListas();
            } else {
                if (this.paramsRoute.listaId) {
                    this.obterListas();
                } else {
                    this.obterCarteiras();
                }
            }
        });
    }

    tabChange(e: MatTabChangeEvent) {

        this.tabSelected = e.index;
    }

    disabledTab(index: number) {
        return !(this.tabSelected >= index);
    }

    //#endregion

    //#region [ Carregamento da tela ]

    obterEstrategiaAcaoCampanhaTipos() {
        this.defaultValuesService
            .obterEstrategiaAcaoCampanhaTipos()
            .then(tipos => (this.estrategiaAcaoCampanhaTipos = tipos));
    }

    obterCarteiras() {
        this.carteiraService.obterCarteiras().subscribe(carteiras => {
            this.carteiras = carteiras;
            if(isNullOrZero (this.estrategia.listaId)) {

            if (this.preferenciasUsuario.existePreferenciaCarteira) {
                this.carteiraSelecionada = this.preferenciasUsuario.carteiraId as number;
            } else {
                let padrao = this.carteiras.find(f => f.padrao) as Carteira;
                this.carteiraSelecionada = padrao.carteiraId;
            }
            }

            this.obterListas();
            this.carteiras.sort((a,b) => {
					if ( a.nome.toLowerCase() < b.nome.toLowerCase()){
						return -1;
					}
					if ( a.nome.toLowerCase() > b.nome.toLowerCase()){
						return 1;
					}
					return 0;
				});
        });
    }

    obterListas() {

        let filtro: any = (!isNullOrZero(this.carteiraSelecionada)) ? { "carteiraId": this.carteiraSelecionada } : {};

        this.listaService
            .obterListaComTemplate(filtro)
            .subscribe((listas: any) => {
                this.listas = listas.map((l: any) => FormEstrategiaListaSelecionada.fromRaw(l)).sort((a,b) => {
					if ( a.nome.toLowerCase() < b.nome.toLowerCase()){
						return -1;
					}
					if ( a.nome.toLowerCase() > b.nome.toLowerCase()){
						return 1;
					}
					return 0;
				});
                
            

                if (this.edit) {
                    this.obterEstrategia(this.paramsRoute.id);

                } else {
                    this.setLista(this.paramsRoute);
                    this.finishedLoading = true;
                }

                if (this.preferenciasUsuario.existePreferenciaLista
                    && isNullOrZero (this.estrategia.listaId)) {

                    this.estrategia.listaId = this.preferenciasUsuario.listaId as number;

                    this.setAgendamentoMinimoMinutos();
                    this.setRegua(this.paramsRoute);
                    this.setFluxo(this.paramsRoute);
                    this.obterProvedores();
                    this.obterTemplatesExportacao();


                }
            });
    }

    obterProvedores() {
        this.providerService
            .obterProvedorDisponivelPorLista(this.estrategia.listaId)
            .subscribe((providers: any) => {
                this.providers = providers.map((p: any) => this.providerService.convertObject(p));

                if (providers.length == 0) {
                    this.providers = new Array<Provider>();
                    this.estrategia.providerId = 0;
                    this.estrategia.listaTemplateExportacaoId = 0;
                    // Swal.fire(
                    //     'Alerta!',
                    //     'Não existe provedor e template de exportação criados para esta lista',
                    //     'warning'
                    //     )
                    this.customAlertService.show(
                        "telaEstrategia.alerta",
                        "telaEstrategia.mensagemProvedor",
                        "warning"
                    );
                }

                if (this.edit) {
                    this.providerSelecionado = this.providers.find((f: Provider) => f.providerId == this.estrategia.providerId) as Provider;

                    // Seta o listaEstrategiaTipoId
                    this.estrategia.listaEstrategiaTipoId = (!isNullOrEmpty(this.providerSelecionado.envio.integracaoTipoId == IntegracaoTipo.Arquivo)) ? 2 : 1;

                    // Verifica se a configuação é por campanha
                    if (this.providerSelecionado.configuracaoPorCampanha)
                        this.obterCampanhas();
                }
            });
    }

    obterTemplatesExportacao() {
        this.templateService
            .obterTemplates({ listaId: this.estrategia.listaId, filtroEstrategia: true, categoria: 2 })
            .subscribe((templates: any) => {
                this.templatesExportacao = templates.map((t: any) => Template.fromRaw(t));

                if (this.edit) {
                    this.templatesExportacaoFiltrado = this.templatesExportacao.filter((f: any) => (f.providerId == this.estrategia.providerId));
                    this.templateExportacaoSelecionado = this.templatesExportacaoFiltrado.find((f: Template) => f.listaTemplateId == this.estrategia.listaTemplateExportacaoId.toString()) as Template;
                }
            });
    }

    obterCampanhas() {
        this.providerService
            .obterCampanhasConfiguradas(this.estrategia.listaId, this.estrategia.providerId)
            .subscribe((campanhas: any) => {
                this.campanhas = campanhas.map(item => {
                    return {key: item.value + "-" + item.key, value: item.value}
                }).sort((a,b) => {
					if ( a.key.toLowerCase() < b.key.toLowerCase()){
						return -1;
					}
					if ( a.key.toLowerCase() > b.key.toLowerCase()){
						return 1;
					}
					return 0;
				});
                if (!isNull(this.providerSelecionado!.campanha) && this.providerSelecionado!.campanha.layoutPorCampanha && !isNullOrZero(this.templateExportacaoSelecionado!.campanhaId)) {
                    this.estrategia.campanhaId = this.templateExportacaoSelecionado!.campanhaId.toString();
                    this.campanhasSelecionada = this.campanhas.find((f: any) => f.campanhaId == this.templateExportacaoSelecionado!.campanhaId);
                }
            });
    }

    obterEstrategiasCampanhasBase() {

        let p1 = this.estrategiaService.obterEstrategias({ 'lista': this.estrategia.listaId, 'provider': this.estrategia.providerId, 'estrategiaAcaoCampanhaTipos': [EstrategiaAcaoCampanhaTipo.Exportacao] });
        let p2 = this.providerService.obterCampanhasConfiguradas(this.estrategia.listaId, this.estrategia.providerId);

        forkJoin([p1, p2]).subscribe((results: any) => {

            let estrategias: Array<any> = results[0]
                .filter((f: any) => f.listaEstrategiaId != this.estrategia.listaEstrategiaId)
                .sort((a: any, b: any) => (a.nome > b.nome ? 1 : 0))
                .map((m: any) => {
                    let campanha = results[1].find((f: any) => f.value == m.campanhaId);
                    return {
                        'estrategiaId': m.listaEstrategiaId,
                        'estrategiaNome': m.nome,
                        'estrategiaFiltro': m.filtro,
                        'estrategiaFiltroRegras': m.filtroRegras,
                        'campanhaId': (isNull(campanha)) ? m.campanhaId : campanha.value,
                        'campanhaNome': (isNull(campanha)) ? 'Não identificado' : campanha.key
                    }
                });

            this.estrategiasCampanhaBase = estrategias.map((e: any) => FormEstrategiaEstrategiaCampanhaBase.fromRaw(e))
        });
    }

    obterEstrategia(estrategiaId: number) {
        this.estrategiaService
            .obterEstrategiaPorId(estrategiaId)
            .subscribe(estrategia => {
                this.estrategia = EstrategiaRefactor.fromRaw(estrategia);

                if (!isNull(this.estrategia.campanhaId))
                    this.estrategia.campanhaId = this.estrategia.campanhaId.toString();

                this.estrategia.limitarExportacao = !this.estrategia.medidaMaximaExportacaoPct;
                // let lista = this.listas.find((f: FormEstrategiaListaSelecionada) => (f.listaId == this.estrategia.listaId))
                // this.listaSelecionada.setar(lista);
                this.listaSelecionada = this.listas.find((f: FormEstrategiaListaSelecionada) => (f.listaId == this.estrategia.listaId)) as FormEstrategiaListaSelecionada;

                this.agendamento = AgendamentoModel.convertEstrategiaToAgendamento(this.estrategia);

                this.query = JSON.parse(estrategia.filtro);
                this.dataRules = ESBuilderData.fromRaw(JSON.parse(estrategia.filtroRegras));

                this.setAgendamentoMinimoMinutos();
                this.obterProvedores();
                this.obterTemplatesExportacao();
                this.obterHorariosOcupados();
                this.carregarMarcarListaPadraoSelecionada();

                this.finishedLoading = true;
            });
    }

    obterHorariosOcupados() {
        this.estrategiaService
            .obterHorariosReservados(this.estrategia.listaId, this.estrategia.listaEstrategiaId)
            .subscribe(horarios => {
                this.horariosReservados = horarios;
            });
    }

    //#endregion

    //#region [ Acoes padrão da tela ]

    setValoresPadraoEstrategiaExportacao() {
        this.estrategia.reenviarDistribuido = FiltroStatusRegistroLista.naoUtilizado;
        this.estrategia.listaEstrategiaTipoId = TipoEstrategia.provedor;
        this.estrategia.enviarEstrategiaIdExportacao = false;
        this.estrategia.marcarDistribuido = true;
        this.estrategia.recorrente = false;
        this.estrategia.envioImediato = false;
        this.estrategia.notificarZerada = false;
        this.estrategia.quantidadeMaximaExportacao = 0;
        this.estrategia.quantidadeMaximaExportacaoPct = 100;
        this.estrategia.medidaMaximaExportacaoPct = true;
    }

    setValoresPadraoEstrategiaRemocaoCampanhaEpbx() {
        this.estrategia.listaEstrategiaTipoId = TipoEstrategia.provedor;
        this.estrategia.providerId = 1;
        this.estrategia.marcarDistribuido = false;
        this.estrategia.recorrente = false;
        this.estrategia.quantidadeMaximaExportacao = 0;
        this.estrategia.quantidadeMaximaExportacaoPct = 100;
        this.estrategia.medidaMaximaExportacaoPct = true;
    }

    setAgendamentoMinimoMinutos() {
        if (!isNullOrZero(this.appConfig.estrategiaAgendamentoMinimoMinutos))
            this.estrategia.estrategiaAgendamentoMinimoMinutos = this.appConfig.estrategiaAgendamentoMinimoMinutos;
    }

    setLista(params: any) {
        if (params.listaId) {
            this.estrategia.listaId = parseInt(params.listaId);
            // let lista = this.listas.find((f: FormEstrategiaListaSelecionada) => (f.listaId == this.estrategia.listaId));
            // this.listaSelecionada.setar(lista);
            this.listaSelecionada = this.listas.find((f: FormEstrategiaListaSelecionada) => (f.listaId == this.estrategia.listaId)) as FormEstrategiaListaSelecionada;
            this.bloquearEscolhaLista = true;
            this.obterProvedores();
            this.obterTemplatesExportacao();
        }
    }

    setRegua(params: any) {
        if (params.reguaId) {
            this.estrategia.reguaId = params.reguaId;

            if (params.reguaEstrategiaId)
                this.estrategia.reguaEstrategiaId = params.reguaEstrategiaId;
        }
    }

    setFluxo(params: any) {
        if (params.fluxoId) {
            this.estrategia.fluxoId = params.fluxoId;

            if (params.fluxoProcessoId)
                this.estrategia.fluxoProcessoId = params.fluxoProcessoId;
        }
    }

    limparDadosEstrategiaBase() {
        this.estrategia.estrategiaBaseId = null;
        this.estrategia.campanhaId = '';
        this.estrategia.filtro = '';
        this.estrategia.filtroRegras = '';
    }

    //#endregion  

    //#region [ Eventos Change ]

    estrategiaAcaoCampanhaTiposChange(event: any) {

        this.query = JSON.parse('{"size":10,"_source":{"includes":[]},"query":{"bool":{"must":[]}}}');
        this.dataRules = new ESBuilderData();
        this.estrategia.listaId = 0;

        if (isNullOrZero(this.estrategia.listaId)) {
            this.estrategia.providerId = 0;
            this.estrategia.listaEstrategiaTipoId = 0;
            this.estrategia.listaTemplateExportacaoId = null;
            this.estrategia.campanhaId = '';
            this.listaSelecionada.limpar();
            this.providerSelecionado = null;
            this.templateExportacaoSelecionado = null;
            this.campanhasSelecionada = null;
        }

        if (this.estrategia.estrategiaAcaoCampanhaTipoId == EstrategiaAcaoCampanhaTipo.Exportacao) {
            this.limparDadosEstrategiaBase();
            this.setValoresPadraoEstrategiaExportacao();
            return;
        }

        if (this.estrategia.estrategiaAcaoCampanhaTipoId == EstrategiaAcaoCampanhaTipo.RemocaoCampanhaEpbx) {
            this.setValoresPadraoEstrategiaRemocaoCampanhaEpbx();
            return;
        }

        if (this.estrategia.estrategiaAcaoCampanhaTipoId == EstrategiaAcaoCampanhaTipo.RemocaoTodasCampanhasEpbx) {
            this.limparDadosEstrategiaBase();
            this.setValoresPadraoEstrategiaRemocaoCampanhaEpbx();

            //SETAR UM TAMPLATE EXPORTAÇÃO PADRAO

            return;
        }
    }

    listaChange(event: any) {

        if (this.estrategia.estrategiaAcaoCampanhaTipoId == EstrategiaAcaoCampanhaTipo.Exportacao) {

            // Caso nenhuma lista tenha sido selecionada limpa os campos dependentes
            if (isNullOrZero(this.estrategia.listaId)) {
                this.estrategia.providerId = 0;
                this.estrategia.listaEstrategiaTipoId = 0;
                this.estrategia.listaTemplateExportacaoId = 0;
                this.estrategia.campanhaId = '';
                this.listaSelecionada.limpar();
                this.providerSelecionado = null;
                this.templateExportacaoSelecionado = null;
                this.campanhasSelecionada = null;

                return;
            }

            // let lista = this.listas.find((f: FormEstrategiaListaSelecionada) => (f.listaId == this.estrategia.listaId));
            // this.listaSelecionada.setar(lista);
            this.listaSelecionada = this.listas.find((f: FormEstrategiaListaSelecionada) => (f.listaId == this.estrategia.listaId)) as FormEstrategiaListaSelecionada;

            if (!isNull(this.listaSelecionada)) {
                this.query = JSON.parse('{"size":10,"_source":{"includes":[]},"query":{"bool":{"must":[]}}}');
                this.dataRules = new ESBuilderData();
                this.obterProvedores();
                this.obterTemplatesExportacao();
                this.obterHorariosOcupados();
                this.carregarMarcarListaPadraoSelecionada();
            }
        }

        if (this.estrategia.estrategiaAcaoCampanhaTipoId == EstrategiaAcaoCampanhaTipo.RemocaoCampanhaEpbx) {

            // Caso nenhuma lista tenha sido selecionada limpa os campos dependentes
            if (isNullOrZero(this.estrategia.listaId)) {
                this.estrategia.estrategiaBaseId = 0;
                return;
            }

            this.obterEstrategiasCampanhasBase();
        }
    }

    carteiraChange(event: any) {
        this.estrategia.listaId = 0;
        this.listaChange(null);

        this.obterListas();
    }

    estrategiasCampanhaBaseChange(event: any) {

        // Caso nenhum provedor tenha sido selecionado limpa os campos dependentes
        if (isNullOrZero(this.estrategia.estrategiaBaseId)) {
            this.estrategia.estrategiaBaseId = 0;
            this.estrategiasCampanhaBaseSelecionada = null;
            return;
        }

        this.estrategiasCampanhaBaseSelecionada = this.estrategiasCampanhaBase.find((f: FormEstrategiaEstrategiaCampanhaBase) => f.estrategiaId == this.estrategia.estrategiaBaseId) as FormEstrategiaEstrategiaCampanhaBase;

        let query = JSON.parse(this.estrategiasCampanhaBaseSelecionada.estrategiaFiltro);
        let dataRules = ESBuilderData.fromRaw(JSON.parse(this.estrategiasCampanhaBaseSelecionada.estrategiaFiltroRegras));

        dataRules.rules.forEach((rule: ESBuilderRules) => (rule.ruleReadOnly = true));

        //  Setando os valores padrões de acordo com a estratégia base selecionada
        this.estrategia.listaTemplateExportacaoId = this.estrategiasCampanhaBaseSelecionada.campanhaId;
        this.estrategia.campanhaId = this.estrategiasCampanhaBaseSelecionada.campanhaId.toString();
        this.estrategia.filtro = this.estrategiasCampanhaBaseSelecionada.estrategiaFiltro;
        this.estrategia.filtroRegras = JSON.stringify(dataRules);

        this.query = query;
        this.dataRules = dataRules;

    }

    providerChange(event: any) {

        // Caso nenhum provedor tenha sido selecionado limpa os campos dependentes
        if (isNullOrZero(this.estrategia.providerId)) {
            this.estrategia.providerId = null;
            this.estrategia.listaEstrategiaTipoId = 0;
            this.estrategia.listaTemplateExportacaoId = null;
            this.estrategia.campanhaId = '';

            this.providerSelecionado = null;
            this.templateExportacaoSelecionado = null;
            this.campanhasSelecionada = null;

            return;
        }

        this.providerSelecionado = this.providers.find((f: Provider) => f.providerId == this.estrategia.providerId) as Provider;

        // Limpa os campos de template e campanha
        this.estrategia.listaTemplateExportacaoId = null;
        this.estrategia.campanhaId = '';

        // Filtra os Templates Exportação do provedor selecionado
        this.templatesExportacaoFiltrado = this.templatesExportacao.filter((f: any) => (f.providerId == this.estrategia.providerId));

        if (this.templatesExportacaoFiltrado.length === 1) {
            this.estrategia.listaTemplateExportacaoId = Number(this.templatesExportacaoFiltrado[0].listaTemplateId);
            this.templateExportacaoSelecionado = this.templatesExportacaoFiltrado[0];
        }

        // Seta o listaEstrategiaTipoId
        this.estrategia.listaEstrategiaTipoId = (!isNullOrEmpty(this.providerSelecionado.envio.integracaoTipoId == IntegracaoTipo.Arquivo)) ? 2 : 1;

        // Verifica se a configuação é por campanha
        if (this.providerSelecionado.configuracaoPorCampanha)
            this.obterCampanhas();
    }

    templateExportacaoChange(event: any) {

        // Caso nenhum template tenha sido selecionado limpa os campos dependentes
        if (isNullOrZero(this.estrategia.listaTemplateExportacaoId)) {
            this.estrategia.campanhaId = '';
            this.campanhasSelecionada = null;
            return;
        }

        this.templateExportacaoSelecionado = this.templatesExportacaoFiltrado.find((f: Template) => f.listaTemplateId == this.estrategia.listaTemplateExportacaoId.toString()) as Template;

        // Limpa os campos de template e campanha
        this.estrategia.campanhaId = '';

        // Verifica se a configuação é por campanha
        if (this.providerSelecionado!.configuracaoPorCampanha)
            this.obterCampanhas();
    }

    updateQuery(event: any) {

        if (!isNullOrEmpty(event.query)) {
            this.query = event.query;
            this.estrategia.filtro = JSON.stringify(event.query);
        }

        if (!isNullOrEmpty(event.dataRules)) {
            this.dataRules = event.dataRules;
            this.estrategia.filtroRegras = JSON.stringify(event.dataRules);
        }


        if (event.standardFilters!= undefined) {

            // Se não tem nenhum instancia o array
            if (isNull(this.estrategia.filtroQueryEstrategias))
                this.estrategia.filtroQueryEstrategias = [];

            // Desativar todos os filtros padrões da estratégia
            this.estrategia.filtroQueryEstrategias.forEach((f: FiltroQueryEstrategia) => f.ativo = false);

            // Reativa ou adiciona os filtros padrões da estratégia
            event.standardFilters.forEach((standardFilterId: number) => {
                let s = this.estrategia.filtroQueryEstrategias.some((s: FiltroQueryEstrategia) => s.filtroQueryId == standardFilterId);
                if (s) {
                    this.estrategia.filtroQueryEstrategias.forEach((f: FiltroQueryEstrategia) => {
                        if (f.filtroQueryId == standardFilterId)
                            f.ativo = true;
                    });
                } else {
                    let fqe = FiltroQueryEstrategia.fromRaw({ filtroQueryId: standardFilterId, listaEstrategiaId: this.estrategia.listaEstrategiaId, ativo: true/*, filtroQuery: item*/ });
                    this.estrategia.filtroQueryEstrategias.push(fqe);
                }
            });
        }
    }

    quantityListChanged(event: any) {
        if (this.dataRules.includeIntegratedRegister == ESBFiltroStatusRegistroLista.naoUtilizado) {
            if (this.estrategia.listaEstrategiaId == 0)   this.estrategia.quantidadeMaximaExportacao = event.quantidadeEstrategia;
            //this.estrategia.quantidadeMaximaExportacao = event.quantidadeEstrategia;
        }
        else {
            this.estrategia.quantidadeMaximaExportacao = 0;
            this.estrategia.quantidadeMaximaExportacaoPct = 100;
            this.estrategia.medidaMaximaExportacaoPct = true;
            this.estrategia.limitarExportacao = false;

        }
    }

    //#endregion

    //#region [ Ordenação ]

    toggleOrdenacao() {
        if (this.listaNaoSelecionada)
            return;

        if (this.mostrarOrdernacao === false) {
            this.ordenacao.setarOrdenacao(this.estrategia.listaEstrategiaPrioridade);
        }

        this.mostrarOrdernacao = !this.mostrarOrdernacao;
    }

    aplicarOrdenacao(event: Array<any>) {
        this.estrategia.listaEstrategiaPrioridade = event;
    }

    carregarMarcarListaPadraoSelecionada() {
        this.listaPrioridadePadraodService.obterListasPrioridadePadrao({ 'listaId': this.estrategia.listaId }).subscribe((result: ListaPrioridadePadrao[]) => {

            this.listaPrioridades = result;
            let ids: any[] = this.estrategia.listaEstrategiaPrioridade.filter((f: Order) => isNullOrZero(f.listaPrioridadePadraoItemId) == false && f.ativo).map((m: any) => m.listaPrioridadePadraoItemId);

            this.listaPrioridades.forEach((f: ListaPrioridadePadrao) => {
                if (f.listaPrioridadePadraoItens.some((s: any) => ids.includes(s.listaPrioridadePadraoItemId)))
                    this.listaPrioridadesSelecionadas.push(f);
            })

        });
    }

    aplicar(ordenacao: ListaPrioridadePadrao) {
        let AddOrdenacao: boolean = false;

        //Verificar se esta adicionando ou removendo uma ordenação padrão
        ordenacao.listaPrioridadePadraoItens.forEach(item => {
            let existe = this.elasticsearchOrder.orderCollection.filter((ordenacaoLista: Order) => item.listaPrioridadePadraoItemId == ordenacaoLista.listaPrioridadePadraoItemId && ordenacaoLista.ativo)

            if (!isNullOrEmpty(existe) || existe.length > 0) {
                if (existe[0].listaEstrategiaPrioridadeId > 0) {
                    this.elasticsearchOrder.orderCollection.forEach(element => {
                        if (element.listaEstrategiaPrioridadeId == existe[0].listaEstrategiaPrioridadeId)
                            element.ativo = false;
                    });
                }
                else {
                    this.elasticsearchOrder.orderCollection = this.elasticsearchOrder.orderCollection.filter((f: Order) => f.listaPrioridadePadraoItemId !== item.listaPrioridadePadraoItemId);
                }
            }
            else {
                if (!AddOrdenacao)
                    AddOrdenacao = true;
            }
        });

        let ordenacaoFinal = [];
        //Separa as ordenações por grupo para adicionar na ordem correta
        let ordernacaoPadrao = this.elasticsearchOrder.activeOrderCollection.filter((f: Order) => !isNullOrZero(f.listaPrioridadePadraoItemId) && f.ativo);
        let ordenacaoLocal = this.elasticsearchOrder.activeOrderCollection.filter((f: Order) => isNullOrZero(f.listaPrioridadePadraoItemId) && f.ativo);
        let ordenacaoInativo = this.elasticsearchOrder.orderCollection.filter((f: Order) => !isNullOrZero(f.listaEstrategiaPrioridadeId) && !f.ativo);
        //Primeiro as ordenações padrões já existente
        ordernacaoPadrao.forEach(element => {
            element.ordem = ordenacaoFinal.length;
            ordenacaoFinal.push(element);
        });

        //adiciona nova ordenação
        if (AddOrdenacao) {
            let campoNaoAdicionado = true;

            //Valida se não já existe esse campo já adicionado em outra ordenação
            ordenacao.listaPrioridadePadraoItens.forEach(item => {
                let existe = this.elasticsearchOrder.orderCollection.filter((ordenacaoLista: Order) => item.listaTemplateItemId == ordenacaoLista.listaTemplateItemId && ordenacaoLista.ativo)

                if (!isNullOrEmpty(existe) || existe.length > 0) {
                    this.customAlertService.show("telaPrioridadePadrao.PrioridadePadrao", "telaPrioridadePadrao.erroAdd", "error");
                    campoNaoAdicionado = false;
                    return
                }
            });

            if (campoNaoAdicionado) {
                this.listaPrioridadesSelecionadas.push(ordenacao);

                //Segundo a nova ordenação padrão
                ordenacao.listaPrioridadePadraoItens.forEach(element => {
                    element.listaPrioridadePadraoNome = ordenacao.nome;
                    element.ordem = ordenacaoFinal.length;
                    ordenacaoFinal.push(element);
                });
            }
        }
        else {
            this.listaPrioridadesSelecionadas = this.listaPrioridadesSelecionadas.filter(o => o !== ordenacao);
        }

        //Terceiro as ordenações locais
        ordenacaoLocal.forEach(element => {
            element.ordem = ordenacaoFinal.length;
            ordenacaoFinal.push(element);
        });

        //Adicionar as ordenações para inativar
        ordenacaoInativo.forEach(element => {
            element.ordem = ordenacaoFinal.length;
            ordenacaoFinal.push(element);
        });

        this.elasticsearchOrder.orderCollection = ordenacaoFinal;
        this.estrategia.listaEstrategiaPrioridade = ordenacaoFinal;
        this.toggleOrdenacao();
    }

    selecionado(item) {
        return this.listaPrioridadesSelecionadas.filter(o => o === item).length > 0;
    }

    //#endregion

    //#region [ Modal agendamento ]

    // abrirModalAgendamento() {
    //     // campo dos radios
    //     if (this.estrategia.recorrente == false) {
    //         this.estrategia.dataInicio = null;
    //         this.estrategia.horaInicio = null;
    //         this.estrategia.dataTermino = null;
    //         this.estrategia.horaTermino = null;
    //         this.estrategia.intervalo = null;
    //         this.estrategia.diaSemana = null;
    //         return;
    //     } else {
    //         // if (!this.isEdit)
    //         //     this.estrategia.envioImediato = true;
    //     }

    //     let agendamentoAnterior = {
    //         dataInicio: this.estrategia.dataInicio,
    //         horaInicio: this.estrategia.horaInicio,
    //         dataTermino: this.estrategia.dataTermino,
    //         horaTermino: this.estrategia.horaTermino,
    //         intervalo: this.estrategia.intervalo,
    //         diaSemana: this.estrategia.diaSemana
    //     };

    //     const agendamentoRef = this.dialog.open(AgendamentoEstrategiaComponent, {
    //         hasBackdrop: true,
    //         width: "800px",
    //         height: "720px",
    //         data: {
    //             estrategia: this.estrategia,
    //             dirty: this.dirty
    //         }
    //     });

    //     agendamentoRef.afterClosed().subscribe((estrategia: any) => {
    //         this.dirty = true;
    //         if (estrategia) return;
    //         Object.assign(this.estrategia, agendamentoAnterior);
    //     });
    // }

    //#endregion

    //#region [ Salvar ]  

    salvarEstrategia() {
        this.dirty = true;
        this.estrategia.aplicarRegraExportacaoLimitada();

        if (this.dataRules.rules.length > 0) {
            if (this.dataRules.rules.some(s => s._valid == false)) {
                this.customAlertService.show("telaEstrategia.estrategia", "telaEstrategia.filtrosInvalidos", "error");
                return;
            }
        }

        if (this.estrategia.validar(this.validarCampoCampanha)) {
            this.customAlertService.show("telaEstrategia.estrategia", "telaPadrao.camposInvalidos", "error");
            return;
        }

        const requisicao = (this.edit == false) ? this.estrategiaService.criarEstrategiaRefactor(this.estrategia) : this.estrategiaService.atualizarEstrategiaRefactor(this.estrategia);

        requisicao.subscribe(
            () => {
                this.customAlertService.show("telaEstrategia.estrategia", "telaEstrategia.salvarEstrategia", "success");

                if (!isNullOrEmpty(this.estrategia.reguaId))
                    this.router.navigate([`/app/regua/${this.estrategia.reguaId}`]);

                else if (!isNullOrEmpty(this.estrategia.fluxoId))
                    this.router.navigate([`/app/fluxo/${this.estrategia.fluxoId}`]);

                else
                    this.router.navigate(["/app/estrategia"]);

            },
            (error: any) => {
                this.customAlertService.show("telaEstrategia.estrategia", error.error[0], "error");
            });
    }

    voltar() {
        this.location.back();
    }

    //#endregion



    //this.estrategia = Object.assign(this.estrategia, event);

    agendamentoChange(event: AgendamentoModel) {
        this.estrategia = Object.assign(this.estrategia, event);
    }
}