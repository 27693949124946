import { Routes } from "@angular/router";
import { FormUsuarioComponent } from "./usuario/form-usuario/form-usuario.component";

import { FormTemplateImportacaoComponent } from "./template/form-template-importacao/form-template-importacao.component";
import { FormTemplateExportacaoComponent } from "./template/form-template-exportacao/form-template-exportacao.component";

import { ListaTemplateComponent } from "./template/lista-template/lista-template.component";
import { ListaComponent } from "./lista/lista/lista.component";
import { FormListaComponent } from "./lista/form-lista/form-lista.component";
import { ListaCadastroComponent } from "./lista/lista-cadastro/lista-cadastro.component";
import { ListaEstrategiaComponent } from "./estrategia/lista-estrategia/lista-estrategia.component";
import { FormEstrategiaComponent } from "./estrategia/form-estrategia/form-estrategia.component";
import { AuthGuard } from "../modules/common/auth.guard";
import { HistoricoEstrategiaComponent } from "./estrategia/historico-estrategia/historico-estrategia.component";
import { UploadArquivosComponent } from "./lista/upload-arquivos/upload-arquivos.component";
import { TabsUsuarioComponent } from "./usuario/tabs-usuario/tabs-usuario.component";
import { FormApiKeyComponent } from "./usuario/form-apikey/form-apikey.component";
import { AcessoNegadoComponent } from "./autenticacao/acesso-negado/acesso-negado.component";
import { DashPrincipalComponent } from "./dash/dash-principal/dash-principal.component";
import { FormProviderComponent } from "./provider/form-provider/form-provider.component";
import { ListaProviderComponent } from "./provider/lista-provider/lista-provider.component";
import { ListaTarefaComponent } from "./tarefa/lista-tarefa/lista-tarefa.component"
import { HistoricoIndexacaoComponent } from "./lista/historico-indexacao/historico-indexacao.component";
import { PesquisaEstrategiaComponent } from "./pesquisa/pesquisa-estrategia/pesquisa-estrategia.component";

import { ListaDashboardComponent } from "./lista/lista-dashboard/lista-dashboard.component";
import { ListaReguaEstrategiaComponent } from "./regua-estrategia/lista-regua-estrategia/lista-reguaestrategia.component";
import { FormReguaEstrategiaComponent } from './regua-estrategia/form-regua-estrategia/form-regua-estrategia.component';
import { DashListaProvedorComponent } from "./dash/dash-lista-provedor/dash-lista-provedor.component";

import { DashEstrategiasComponent } from "./dash/dash-estrategias/dash-estrategias.component";
import { ListaFluxoEstrategiaComponent } from "./fluxo-estrategia/lista-fluxo-estrategia/lista-fluxoestrategia.component";
import { FormFluxoEstrategiaComponent } from "./fluxo-estrategia/form-fluxo-estrategia/form-fluxo-estrategia.component";
import { ListaDocumentacaoComponent } from "./documentacao/lista-documentacao/lista-documentacao.component";
import { HistoricoRetornoComponent } from "./retorno/historico-retorno/historico-retorno.component";
import { DashIndividualComponent } from "./dash/dash-individual/dash-individual.component";
import { ListaNotificacaoComponent } from "./notificacao/lista-notificacao/lista-notificacao.component";
import { DownloadRegistroComponent } from "./download-registro/download-registro.component";
import { PesquisaListaComponent } from "./pesquisa/pesquisa-lista/pesquisa-lista.component";
import { MonitorRetornoComponent } from "./retorno/monitor-retorno/monitor-retorno.component";
import { ListaFiltroQueryPadraoComponent } from "./filtro-query-padrao/lista-filtro-query-padrao/lista-filtro-query-padrao.component";
import { FormFiltroQueryPadraoComponent } from "./filtro-query-padrao/form-filtro-query-padrao/form-filtro-query-padrao.component";
import { ListaConfiguracaoComponent } from "./configuracao/lista-configuracao/lista-configuracao.component";
import { ListaCarteiraComponent } from "./carteira/lista-carteira/lista-carteira.component";
import { FormCarteiraComponent } from "./carteira/form-carteira/form-carteira.component";
import { FormPlanoTarifaProvedorComponent } from "./plano-tarifa-provedor/form-plano-tarifa-provedor/form-plano-tarifa-provedor.component";
import { ListaIndicadorProvedorComponent } from "./indicador-provedor/lista-indicador-provedor/lista-indicador-provedor.component";
import { AlterarSenhaExpiradaComponent } from "./autenticacao/alterar-senha-expirada/alterar-senha-expirada.component";
import { EsBuilderTestComponent } from "./esbuilder/esbuilder-test/esbuilder-test.component";
import { ListaElasticSearchComponent } from "./elasticsearch/lista-elasticsearch/lista-elasticsearch.component";
import { FormElasticSearchComponent } from "./elasticsearch/form-elasticsearch/form-elasticsearch.component";
import { FormPerfilComponent } from "./perfil/form-perfil/form-perfil.component";
import { FormOrdenacaoPadraoComponent } from "./ordenacao-padrao/form-ordenacao-padrao/form-ordenacao-padrao.component";
import { ListaOrdenacaoPadraoComponent } from "./ordenacao-padrao/lista-ordenacao-padrao/lista-ordenacao-padrao.component";

export const routes: Routes = [
	{
		path: "",
		redirectTo: "/app/home",
		pathMatch: "full",
		canActivate: [AuthGuard]
	},
	{
		path: "acesso-negado",
		component: AcessoNegadoComponent,
		canActivate: [AuthGuard]
	},
	{
		path: "home",
		component: DashPrincipalComponent,
		canActivate: [AuthGuard],
		data: {
			label: "rota.home",
			permissao: { modulo: "Dashboard", acao: "Leitura" }
		}
	},
	{
		path: "perfil",
		component: FormPerfilComponent,
		canActivate: [AuthGuard],
		data: {
			label: "rota.perfil",
			permissao: { modulo: "Dashboard", acao: "Leitura" }
		}
	},
	{
		data: {
			label: "EsBuilder Teste",
			parent: "home"
		},
		path: "esbuilder/test",
		component: EsBuilderTestComponent
	},
	{
		data: {
			label: "rota.dashprovedor",
			parent: "home",
			permissao: { modulo: "DashboardListaProvedor", acao: "Leitura" }
		},
		path: "dashboard/lista-provedor",
		component: DashListaProvedorComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "rota.dashIndividual",
			parent: "home",
			permissao: { modulo: "DashboardIndividual", acao: "Leitura" }
		},
		path: "dashboard/individual",
		component: DashIndividualComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "rota.dashEstrategia",
			parent: "home",
			permissao: { modulo: "DashboardEstrategias", acao: "Leitura" }
		},
		path: "dashboard/estrategias",
		component: DashEstrategiasComponent,
		canActivate: [AuthGuard]
	},
	////////////////////////////////////////////////////////////////////
	/// TEMPLATE ///////////////////////////////////////////////////////
	{
		data: {
			label: "rota.template",
			permissao: { modulo: "Template", acao: "Leitura" }
		},
		path: "template",
		component: ListaTemplateComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "rota.novoTemplate",
			parent: "template",
			permissao: { modulo: "Template", acao: "Escrita" }
		},
		path: "template/create/:duplicar",
		component: FormTemplateImportacaoComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "rota.novoTemplate",
			parent: "template",
			permissao: { modulo: "Template", acao: "Escrita" }
		},
		path: "template/create",
		component: FormTemplateImportacaoComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "rota.editarTemplate",
			parent: "template",
			permissao: { modulo: "Template", acao: "Escrita" }
		},
		path: "template/:id",
		component: FormTemplateImportacaoComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "rota.novoTemplateExportacao",
			parent: "template/:id",
			permissao: { modulo: "Template", acao: "Escrita" }
		},
		path: "template/:id/create/:duplicar",
		component: FormTemplateExportacaoComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "rota.novoTemplateExportacao",
			parent: "template/:id",
			permissao: { modulo: "Template", acao: "Escrita" }
		},
		path: "template/:id/create",
		component: FormTemplateExportacaoComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "rota.editarTemplateExportacao",
			parent: "template/:id",
			permissao: { modulo: "Template", acao: "Escrita" }
		},
		path: "template/:id/:idExportacao",
		component: FormTemplateExportacaoComponent,
		canActivate: [AuthGuard]
	},
	////////////////////////////////////////////////////////////////////
	/// LISTA //////////////////////////////////////////////////////////
	{
		data: { label: "rota.lista", permissao: { modulo: "Lista", acao: "Leitura" } },
		path: "lista",
		component: ListaComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "rota.viewLista",
			parent: "lista",
			permissao: { modulo: "Lista", acao: "Leitura" }
		},
		path: "lista/dashboard",
		component: ListaDashboardComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "rota.novaLista",
			parent: "lista",
			permissao: { modulo: "Lista", acao: "Escrita" }
		},
		path: "lista/create",
		component: ListaCadastroComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "rota.editarItemLista",
			parent: "lista",
			permissao: { modulo: "Lista", acao: "Escrita" }
		},
		path: "lista/form-lista/:id",
		component: FormListaComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "rota.viewIdexacao",
			parent: "lista",
			permissao: { modulo: "Lista", acao: "Leitura" }
		},
		path: "lista/dashboard/:id",
		component: ListaDashboardComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "rota.importarLista",
			parent: "lista",
			permissao: { modulo: "Lista", acao: "Escrita" }
		},
		path: "lista/:id/importacao",
		component: UploadArquivosComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "rota.editarLista",
			parent: "lista",
			permissao: { modulo: "Lista", acao: "Escrita" }
		},
		path: "lista/:id",
		component: ListaCadastroComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "rota.editarLista",
			parent: "lista",
			permissao: { modulo: "Lista", acao: "Escrita" }
		},
		path: "lista/:id/alteracao/:type",
		component: ListaCadastroComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "rota.estrategia",
			permissao: { modulo: "Estrategia", acao: "Leitura" }
		},
		path: "lista/:listaId/estrategia",
		component: ListaEstrategiaComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "rota.lista",
			permissao: { modulo: "Estrategia", acao: "Escrita" }
		},
		path: "lista/:listaId/estrategia/create",
		component: FormEstrategiaComponent,
		canActivate: [AuthGuard]
	},
	////////////////////////////////////////////////////////////////////
	/// ESTRATEGIA /////////////////////////////////////////////////////
	{
		data: {
			label: "rota.estrategia",
			permissao: { modulo: "Estrategia", acao: "Leitura" }
		},
		path: "estrategia",
		component: ListaEstrategiaComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "rota.novaEstrategia",
			parent: "estrategia",
			permissao: { modulo: "Estrategia", acao: "Escrita" }
		},
		path: "estrategia/create",
		component: FormEstrategiaComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "rota.editarEstrategia",
			parent: "estrategia",
			permissao: { modulo: "Estrategia", acao: "Escrita" }
		},
		path: "estrategia/:id",
		component: FormEstrategiaComponent,
		canActivate: [AuthGuard]
	},
	////////////////////////////////////////////////////////////////////
	/// CARTEIRA ///////////////////////////////////////////////////////
	{
		data: {
			label: "rota.carteira",
			permissao: { modulo: "Carteira", acao: "Leitura" }
		},
		path: "carteira",
		component: ListaCarteiraComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "rota.novaCarteira",
			parent: "carteira",
			permissao: { modulo: "Carteira", acao: "Escrita" }
		},
		path: "carteira/create",
		component: FormCarteiraComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "rota.editarCarteira",
			parent: "carteira",
			permissao: { modulo: "Carteira", acao: "Escrita" }
		},
		path: "carteira/:id",
		component: FormCarteiraComponent,
		canActivate: [AuthGuard]
	},
	////////////////////////////////////////////////////////////////////
	/// REGUA //////////////////////////////////////////////////////////
	{
		data: {
			label: "rota.regua",
			permissao: { modulo: "Regua", acao: "Leitura" }
		},
		path: "regua",
		component: ListaReguaEstrategiaComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "rota.novaRegua",
			parent: "regua",
			permissao: { modulo: "Regua", acao: "Escrita" }
		},
		path: "regua/create",
		component: FormReguaEstrategiaComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "rota.editarRegua",
			parent: "regua",
			permissao: { modulo: "Regua", acao: "Escrita" }
		},
		path: "regua/:id",
		component: FormReguaEstrategiaComponent,
		canActivate: [AuthGuard]
	},
	////////////////////////////////////////////////////////////////////
	/// FLUXO //////////////////////////////////////////////////////////
	{
		data: {
			label: "rota.fluxo",
			permissao: { modulo: "Fluxo", acao: "Leitura" }
		},
		path: "fluxo",
		component: ListaFluxoEstrategiaComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "rota.novoFluxo",
			parent: "fluxo",
			permissao: { modulo: "Fluxo", acao: "Escrita" }
		},
		path: "fluxo/create",
		component: FormFluxoEstrategiaComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "rota.editarFluxo",
			parent: "fluxo",
			permissao: { modulo: "Fluxo", acao: "Escrita" }
		},
		path: "fluxo/:id",
		component: FormFluxoEstrategiaComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "rota.fluxo",
			permissao: { modulo: "Fluxo", acao: "Escrita" }
		},
		path: "fluxo/:fluxoId/:fluxoProcessoId/:listaId/estrategia/create",
		component: FormEstrategiaComponent,
		canActivate: [AuthGuard]
	},
	////////////////////////////////////////////////////////////////////
	/// HISTÓRICO DE ESTRATEGIA ////////////////////////////////////////
	{
		data: {
			label: "rota.historicoestrategia",
			parent: "estrategia",
			permissao: { modulo: "Estrategia", acao: "Leitura" }
		},
		path: "estrategia/:id/historico",
		component: HistoricoEstrategiaComponent,
		canActivate: [AuthGuard]
	},
	{
		path: "",
		redirectTo: "/app/usuario",
		pathMatch: "full",
		canActivate: [AuthGuard]
	},
	{
		data: { label: "rota.usuario" },
		path: "usuario",
		component: TabsUsuarioComponent,
		canActivate: [AuthGuard]
	},
	{
		data: { label: "rota.novoUsuario", parent: "usuario" },
		path: "usuario/create",
		component: FormUsuarioComponent,
		canActivate: [AuthGuard]
	},
	{
		data: { label: "rota.novaApikey", parent: "usuario" },
		path: "apikey/create",
		component: FormApiKeyComponent,
		canActivate: [AuthGuard]
	},
	{
		data: { label: "rota.editarUsuario", parent: "usuario" },
		path: "usuario/:id",
		component: FormUsuarioComponent,
		canActivate: [AuthGuard]
	},
	{
		data: { label: "rota.editarApikey", parent: "usuario" },
		path: "apikey/:id",
		component: FormApiKeyComponent,
		canActivate: [AuthGuard]
	},
	{
		data: { label: "rota.provedor" },
		path: "provedor",
		component: ListaProviderComponent,
		canActivate: [AuthGuard]
	},
	{
		data: { label: "rota.novoProvedor", parent: "provedor" },
		path: "provedor/create",
		component: FormProviderComponent,
		canActivate: [AuthGuard]
	},
	{
		data: { label: "rota.editarProvedor", parent: "provedor" },
		path: "provedor/:id",
		component: FormProviderComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "rota.execucaoEstrategia", permissao: { modulo: "Tarefa", acao: "Leitura" }
		},
		path: "tarefa",
		component: ListaTarefaComponent,
		canActivate: [AuthGuard]
	},
	////////////////////////////////////////////////////////////////////
	/// DOCUMENTAÇÃO ///////////////////////////////////////////////////
	{
		data: {
			label: "rota.documentacao",
			permissao: { modulo: "Documentacao", acao: "Leitura" }
		},
		path: "documentacao",
		component: ListaDocumentacaoComponent,
		canActivate: [AuthGuard]
	},
	////////////////////////////////////////////////////////////////////
	/// HISTORICO RETORNO//////////////////////////////////////////////
	{
		data: {
			label: "rota.historicoRetorno",
			permissao: { modulo: "HistoricoRetorno", acao: "Leitura" }
		},
		path: "historicoretorno",
		component: HistoricoRetornoComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "rota.historicoRetorno",
			permissao: { modulo: "HistoricoRetorno", acao: "Leitura" }
		},
		path: "historicoretorno/:id",
		component: HistoricoRetornoComponent,
		canActivate: [AuthGuard]
	},
	////////////////////////////////////////////////////////////////////
	/// MONITOR RETORNO/////////////////////////////////////////////////
	{
		data: {
			label: "rota.monitorRetorno",
			permissao: { modulo: "MonitorRetorno", acao: "Leitura" }
		},
		path: "monitorretorno",
		component: MonitorRetornoComponent,
		canActivate: [AuthGuard]
	},
	////////////////////////////////////////////////////////////////////
	/// NOTIFICACAO////////////////////////////////////////////////////
	{
		data: {
			label: "rota.notificacao",
			permissao: { modulo: "Notificacao", acao: "Leitura" }
		},
		path: "notificacao",
		component: ListaNotificacaoComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "rota.notificacao",
			permissao: { modulo: "Notificacao", acao: "Leitura" }
		},
		path: "notificacao/:id",
		component: ListaNotificacaoComponent,
		canActivate: [AuthGuard]
	},
	////////////////////////////////////////////////////////////////////
	/// DOWNLOADREGISTRO////////////////////////////////////////////////
	{
		data: {
			label: "rota.downloadRegistro",
			permissao: { modulo: "DownloadRegistro", acao: "Leitura" }
		},
		path: "downloadregistro",
		component: DownloadRegistroComponent,
		canActivate: [AuthGuard]
	},
	////////////////////////////////////////////////////////////////////
	/// PESQUISA////////////////////////////////////////////////////////
	{
		data: {
			label: "rota.pesquisaEstrategia", permissao:
				{ modulo: "PesquisaEstrategia", acao: "Leitura" }
		},
		path: "pesquisaestrategia",
		component: PesquisaEstrategiaComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "rota.pesquisaLista", permissao:
				{ modulo: "PesquisaLista", acao: "Leitura" }
		},
		path: "pesquisalista",
		component: PesquisaListaComponent,
		canActivate: [AuthGuard]
	},
	////////////////////////////////////////////////////////////////////
	/// FILTROQUERY ////////////////////////////////////////////////////
	{
		data: {
			label: "rota.filtroQuery",
			permissao: { modulo: "FiltroQuery", acao: "Leitura" }
		},
		path: "filtroquery",
		component: ListaFiltroQueryPadraoComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "rota.novoFiltroQuery",
			parent: "filtroquery",
			permissao: { modulo: "FiltroQuery", acao: "Escrita" }
		},
		path: "filtroquery/create",
		component: FormFiltroQueryPadraoComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "rota.editarFIltroQuery",
			parent: "filtroquery",
			permissao: { modulo: "FiltroQuery", acao: "Escrita" }
		},
		path: "filtroquery/:id",
		component: FormFiltroQueryPadraoComponent,
		canActivate: [AuthGuard]
	},
		////////////////////////////////////////////////////////////////////
	/// ORDENACAOPADRAO ////////////////////////////////////////////////////
	{
		data: {
			label: "rota.ordenacaoPadrao",
			permissao: { modulo: "OrdenacaoPadrao", acao: "Leitura" }
		},
		path: "ordenacaopadrao",
		component: ListaOrdenacaoPadraoComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "rota.novoOrdenacaoPadrao",
			parent: "ordenacaopadrao",
			permissao: { modulo: "OrdenacaoPadrao", acao: "Escrita" }
		},
		path: "ordenacaopadrao/create",
		component: FormOrdenacaoPadraoComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "rota.editarOrdenacaoPadrao",
			parent: "ordenacaopadrao",
			permissao: { modulo: "OrdenacaoPadrao", acao: "Escrita" }
		},
		path: "ordenacaopadrao/:id",
		component: FormOrdenacaoPadraoComponent,
		canActivate: [AuthGuard]
	},
	////////////////////////////////////////////////////////////////////
	/// CONFIGURAÇÃO ///////////////////////////////////////////////////
	{
		data: {
			label: "rota.configuracao",
			permissao: { modulo: "Configuracao", acao: "Leitura" }
		},
		path: "configuracao",
		component: ListaConfiguracaoComponent,
		canActivate: [AuthGuard]
	},
	////////////////////////////////////////////////////////////////////
	/// PLANO TARIFA PROVEDOR //////////////////////////////////////////
	{
		data: {
			label: "rota.planoTarifa",
			permissao: { modulo: "PlanoTarifaProvedor", acao: "Leitura" }
		},
		path: "planotarifaprovedor/:id",
		component: FormPlanoTarifaProvedorComponent,
		canActivate: [AuthGuard]
	},
	////////////////////////////////////////////////////////////////////
	/// INDICADOR PROVEDOR /////////////////////////////////////////////
	{
		data: {
			label: "rota.indicadorProvedor",
			permissao: { modulo: "IndicadorProvedor", acao: "Leitura" }
		},
		path: "indicadorprovedor",
		component: ListaIndicadorProvedorComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "rota.indicadorProvedor",
			parent: "indicadorprovedor",
			permissao: { modulo: "IndicadorProvedor", acao: "Leitura" }
		},
		path: "indicadorprovedor/:id",
		component: ListaIndicadorProvedorComponent,
		canActivate: [AuthGuard]
	},
	////////////////////////////////////////////////////////////////////
	/// ELASTICSEARCH //////////////////////////////////////////////////
	{
		data: {
			label: "rota.elasticSearch",
			permissao: { modulo: "ElasticSearch", acao: "Leitura" }
		},
		path: "elasticsearch",
		component: ListaElasticSearchComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "rota.novoElasticSearch",
			parent: "elasticsearch",
			permissao: { modulo: "ElasticSearch", acao: "Escrita" }
		},
		path: "elasticsearch/create",
		component: FormElasticSearchComponent,
		canActivate: [AuthGuard]
	},
	{
		data: {
			label: "rota.editarElastiSearch",
			parent: "elasticsearch",
			permissao: { modulo: "ElasticSearch", acao: "Escrita" }
		},
		path: "elasticsearch/:id",
		component: FormElasticSearchComponent,
		canActivate: [AuthGuard]
	},
	////////////////////////////////////////////////////////////////////
	{ path: "**", redirectTo: "/app/usuario" }

];