import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { convertToQueryString } from "./query-string";
import { FiltroNotificacao } from "../model/filtro-notificacao";
import { Notificacao } from "../model/notificacao";

@Injectable()
export class NotificacaoService {
	private api: string;

	constructor(private httpClient: HttpClient) {
		this.api = '/api/notificacao';
	}

	public obterNotificacao(filtro: FiltroNotificacao) {
		return this.httpClient.get<Array<Notificacao>>(`${this.api}${convertToQueryString(filtro)}`);
	}

	public obterNotificacaoPorId(id: number) {
		return this.httpClient.get<Notificacao>(`${this.api}/${id}`);
	}
}