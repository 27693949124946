import { NgModule, LOCALE_ID } from "@angular/core";
import { CommonModule, registerLocaleData } from "@angular/common";
import { RouterModule } from "@angular/router";
import { HttpModule } from "@angular/http";
import { FormsModule } from "@angular/forms";
import {
  MatInputModule,
  MatButtonModule,
  MatCheckboxModule,
  MatDialogModule,
  MatProgressSpinnerModule,
  MatListModule,
  MatIconModule,
  MatExpansionModule,
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatSlideToggleModule,
  MatTooltipModule
} from "@angular/material";
import { BreadcrumbModule } from "primeng/breadcrumb";
import { ListboxModule } from "primeng/listbox";
import { ProgressBarModule } from "primeng/progressbar"

import { Directives } from "./directives";
import { DateFormatPipe } from "./pipes/date-format.pipe";
import { FilterListPipe } from "./pipes/filter-list.pipe";
import { DisplayAtivoPipe } from "./pipes/display-ativo.pipe";
import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb.component";
import { WeekdaySelectionComponent } from "./components/weekday-selection/weekday-selection.component";

import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { LoadingComponent } from "./components/loading/loading.component";
import { LogHistoricoComponent } from "./components/log-historico/log-historico.component";
import { NumberFormatPipe } from "./pipes/number-format.pipe";
import { SuffixNumberPipe } from "./pipes/suffix-number.pipe";
import { PrettyJsonPipe } from "./pipes/pretty-json.pipe";
import { RemoveSpecialCharacterPipe } from "./pipes/remove-special-character";
//import { SelectComponent } from "./components/select/select.component";
import { NgSelectModule } from '@ng-select/ng-select';
import { FileSizeFormatPipe } from "./pipes/file-size-format.pipe";
import { CustomListboxComponent } from "./components/custom-listbox/custom-listbox.component";
import { PermissaoDirective } from "./directives/permissao.directive";
import { CardWithHeaderComponent } from "./components/card-with-header/card-with-header.component";
import { CustomAlertService } from "./service/custom-alert.service";
import { SimNaoPipePipe } from "./pipes/sim-nao-pipe.pipe";
import { TableModule } from "primeng/table";
import { DurationFormatPipe } from "./pipes/duration-format.pipe";
import { environment } from "../../../environments/environment";
import { CampoListaValorModalComponent } from "../template/campo-lista-valor-modal/campo-lista-valor-modal.component";
import { ReplaceBreaklinePipe } from "./pipes/replace-breakline-pipe";
import { CustomToolTipComponent } from "./directives/custom-tool-tip/custom-tool-tip.component";
import { ToolTipRendererDirective } from "./directives/tool-tip-renderer.directive";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "assets/i18n/", ".json");
}

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    MatInputModule,
    MatButtonModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    FormsModule,
    HttpModule,
    MatCheckboxModule,
    MatListModule,
    MatIconModule,
    MatTooltipModule,
    MatExpansionModule,
    BreadcrumbModule,
    ProgressBarModule,
    HttpModule,
    TableModule,
    MatSlideToggleModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgSelectModule,
    ListboxModule
  ],
  declarations: [
    ...Directives,
    DateFormatPipe,
    DisplayAtivoPipe,
    BreadcrumbComponent,
    WeekdaySelectionComponent,
    DateFormatPipe,
    FilterListPipe,
    DisplayAtivoPipe,
    LoadingComponent,
    LogHistoricoComponent,
    NumberFormatPipe,
    RemoveSpecialCharacterPipe,
    SuffixNumberPipe,
    PrettyJsonPipe,
    //SelectComponent,
    FileSizeFormatPipe,
    CustomListboxComponent,
    PermissaoDirective,
    CardWithHeaderComponent,
    SimNaoPipePipe,
    DurationFormatPipe,
    CampoListaValorModalComponent,
    ReplaceBreaklinePipe,
    CustomToolTipComponent, 
    ToolTipRendererDirective
  ],
  providers: [
    { useClass: CustomAlertService, provide: CustomAlertService },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { panelClass: `theme-${environment.theme}` }}
  ],
  entryComponents: [LoadingComponent, LogHistoricoComponent, CampoListaValorModalComponent,		CustomToolTipComponent],
  exports: [
    ...Directives,
    DateFormatPipe,
    DisplayAtivoPipe,
    FilterListPipe,
    HttpClientModule,
    RouterModule,
    TranslateModule,
    BreadcrumbComponent,
    WeekdaySelectionComponent,
    NumberFormatPipe,
    RemoveSpecialCharacterPipe,
    SuffixNumberPipe,
    PrettyJsonPipe,
    //SelectComponent,
    FileSizeFormatPipe,
    CustomListboxComponent,
    CardWithHeaderComponent,
    SimNaoPipePipe,
    DurationFormatPipe,
    ReplaceBreaklinePipe,
    CustomToolTipComponent, 
    ToolTipRendererDirective
  ]
})
export class AppCommonModule { }
