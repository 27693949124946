import "rxjs/add/operator/do";
import "rxjs/add/operator/first";
import "rxjs/add/operator/mergeMap";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import swal from "sweetalert2";
import { Regua } from "../../common/model/regua";
import { ReguaService } from "../../common/service/regua.service";
import { CustomAlertService } from "../../common/service/custom-alert.service";
import { FiltroLista } from "../../common/model/filtro-lista";
import { ListaService } from "../../common/service/lista.service";
import { Lista } from "../../common/model/lista";
import { ReguaEstrategia } from "../../common/model/regua-estrategia";
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { deepCopy } from '../../../modules/common/utils/deepCopy';
import { PreferenciasUsuarioService } from "../../common/service/preferencias-usuario.service";
import { CarteiraService } from "../../common/service/carteira.service";
import { PreferenciasUsuario } from "../../common/model/preferencias-usuario";
import { Carteira } from "../../common/model/carteira";
import { isNullOrZero } from "../../common/utils";
@Component({
	selector: "app-form-regua-estrategia",
	templateUrl: "./form-regua-estrategia.component.html",
	styleUrls: ["./form-regua-estrategia.component.scss"],
	providers: [
		{ useClass: ReguaService, provide: ReguaService },
		{ useClass: CarteiraService, provide: CarteiraService },
		{ useClass: PreferenciasUsuarioService, provide: PreferenciasUsuarioService },
		{ useClass: ListaService, provide: ListaService },
		{ useClass: CustomAlertService, provide: CustomAlertService }
	]
})
export class FormReguaEstrategiaComponent implements OnInit {

	debug: boolean = false;

	dirty: boolean;

	regua: Regua;

	preferenciasUsuario: PreferenciasUsuario;
	carteiras: Array<Carteira>;
	carteiraSelecionada: number;
	listas: Lista[];


	liveError(property: string) {
		if (this.dirty) {
			var validationResult = this.regua.validar();
			if (!validationResult) return null;
			return validationResult[property] ? validationResult[property][0] : null;
		}
	}

	public get erros() {
		if (!this.dirty) return;
		return this.regua.validar();
	}

	public get isEdit(): boolean {
		return this.regua != undefined && this.regua.reguaId != undefined;
	}

	constructor(
		private route: ActivatedRoute,
		private reguaService: ReguaService,
		private carteiraService: CarteiraService,
		private preferenciasService: PreferenciasUsuarioService,
		private listaService: ListaService,
		private router: Router,
		private customAlertService: CustomAlertService
	) {
		// Preferencias do usuário
		this.preferenciasUsuario = this.preferenciasService.localStorageObterPreferenciasUsuario();

		this.regua = new Regua();
		this.route.params.subscribe(params => this.carregar(params));

		this.route.queryParams.subscribe(params => {
			if (params.debug)
				this.debug = (params.debug === 'true');
		});
	}

	ngOnInit() {
		this.obterCarteiras();
	}

	obterCarteiras() {
		this.carteiraService.obterCarteiras().subscribe(carteiras => {
			this.carteiras = carteiras.sort((a,b) => {
                if ( a.nome.toLowerCase() < b.nome.toLowerCase()){
                    return -1;
                }
                if ( a.nome.toLowerCase() > b.nome.toLowerCase()){
                    return 1;
                }
                return 0;
                
            })

			if (this.preferenciasUsuario.existePreferenciaCarteira) {
				this.carteiraSelecionada = this.preferenciasUsuario.carteiraId as number;
			} else {
				let padrao = this.carteiras.find(f => f.padrao) as Carteira;
				this.carteiraSelecionada = padrao.carteiraId;
			}

			this.obterListas();
		});
	}

	obterListas() {
		let filtroLista = new FiltroLista();
		filtroLista.ignorarErroPesquisa = true;

		if (!isNullOrZero(this.carteiraSelecionada))
			filtroLista.carteiraId = this.carteiraSelecionada;

		this.listaService.obterLista(filtroLista).subscribe(listas => {
			this.listas = listas.sort((a,b) => {
                if ( a.nome.toLowerCase() < b.nome.toLowerCase()){
                    return -1;
                }
                if ( a.nome.toLowerCase() > b.nome.toLowerCase()){
                    return 1;
                }
                return 0;
                
            })

			if (this.preferenciasUsuario.existePreferenciaLista) {
				this.regua.listaId = this.preferenciasUsuario.listaId as number;
			}

		});
	}

	carregar(params: any) {
		if (!params.id)
			return;

		this.reguaService.obterPorId(params.id).subscribe((regua: Regua) => this.regua = regua);
	}

	carteiraChange(event: any) {
		this.regua.listaId = 0;
		this.listaChange(null);

		this.obterListas();
	}

	listaChange(event: any) {
		this.regua.reguaEstrategia = [];
	}

	salvar() {
		this.dirty = true;

		if (this.regua.validar()) {
			this.customAlertService.show("telaRegua.regua", "telaPadrao.camposInvalidos", "error");
			return;
		}

		let sucesso = (response: any) => {
			this.customAlertService.show("telaRegua.regua", "telaPadrao.sucessoSalvar", "success");
			this.router.navigate(["/app/regua/"]);
			// if (this.isEdit) {
			// 	this.customAlertService.show("telaRegua.regua", "telaPadrao.sucessoSalvar", "success");
			// 	this.router.navigate(["/app/regua/"]);
			// } else {
			// 	this.customAlertService
			// 		.showWithConfiguration({
			// 			title: "telaRegua.regua",
			// 			text: "telaRegua.reguaSalva",
			// 			icon: "success",
			// 			showCancelButton: true,
			// 			confirmButtonText: "telaPadrao.sim",
			// 			cancelButtonText: "telaPadrao.nao"
			// 		})
			// 		.then(data => {
			// 			if (data.dismiss == swal.DismissReason.cancel)
			// 				return this.router.navigate(["/app/regua"]);

			// 			this.router.navigate(["/app/regua", response["data"]]);
			// 		});
			//}
		};

		let erro = () => { this.customAlertService.show("telaRegua.regua", "telaPadrao.erroSalvar", "error") };

		if (!this.regua.reguaId)
			return this.reguaService.criar(this.regua).subscribe(sucesso, erro);

		this.reguaService.atualizar(this.regua).subscribe(sucesso, erro);
	}
}
