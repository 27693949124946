import { isNull, isNullOrEmpty, isObjectEmpty } from './isNull';
import { esbFormatFieldName } from './esbFormatFieldName';

import { ESBuilderData, ESBuilderQuery, ESBuilderQueryBool, ESBuilderRules, ESBuilderRulesConditions, ESBuilderFilterType, ESBFiltroStatusRegistroLista } from "../../models";
import * as dayjs from 'dayjs';


export class ModelToQuery {

    public static convert(data: ESBuilderData) {

        if (isNullOrEmpty(data))
            return {};

        let bool: ESBuilderQueryBool = new ESBuilderQueryBool();

        // ANALISE DE TODAS AS QUERYS COM CONDIÇÕES SOLICITADAS
        // - se tem OR tem should
        // - se o primeiro é grupo e tem OR dentro tem should
        // - se é um grupo e tem OR dentro o should é colocado dentro do must ou must_not depende do item se é AND OU OR

        let isShouldFatherCondition = data.rules.some((s: ESBuilderRules) => (s.condition == ESBuilderRulesConditions.or.toString()))
        let previousCondition: string = 'and';
        let boolShould: ESBuilderQueryBool;

        data.rules.forEach((ruleItem: ESBuilderRules, index: number) => {

            if (ruleItem.isValid) {

                let excpt = ["vazio", "nao_vazio", "existe", "nao_existe"];

                if (isObjectEmpty(ruleItem.filterValues) && isNull(ruleItem.rules) && !excpt.some((s: string) => (s == ruleItem.filterType)))
                    return;

                // Cria o SHOULD se o primeiro RULEITEM for um grupo e se entre os RULEITEM FILHOS tiver algum que tenha a condição OR
                if (index == 0 && !isNull(ruleItem.rules) && ruleItem.rules.some((s: ESBuilderRules) => (s.condition == ESBuilderRulesConditions.or))) {

                    if (isNull(bool['should']))
                        bool['should'] = [];

                    let boolShould: ESBuilderQueryBool = new ESBuilderQueryBool();
                    let previousChildrenCondition: string = 'and';

                    ruleItem.rules.forEach((ruleChildrenItem: ESBuilderRules) => {

                        // Conversão da condição em filter do ElasticSearch
                        let filter = this.convertCondition(ruleChildrenItem);
                        let condition = (ruleChildrenItem.condition == ESBuilderRulesConditions.or) ? 'or' : 'and';

                        if (previousChildrenCondition != condition) {
                            previousChildrenCondition = condition;
                            bool.should.push({ 'bool': boolShould });
                            boolShould = new ESBuilderQueryBool();
                        }

                        if (isNull(boolShould[filter.needed]))
                            boolShould[filter.needed] = [];

                        boolShould[filter.needed].push(filter.filter)
                    });

                    bool.should.push({ 'bool': boolShould });

                }
                // Cria o SHOULD se o entre os RULEITENS PAIS tiver algum que tenha a condição OR
                else if (isShouldFatherCondition) {

                    // Caso o RULEITEM seja um grupo
                    if (this.isRuleGroup(ruleItem)) {
                        if (!isNull(boolShould) && (!isNull(boolShould.must) || !isNull(boolShould.must_not) || !isNull(boolShould.should))) {
                            bool.should.push({ 'bool': boolShould });
                            boolShould = new ESBuilderQueryBool();
                        }

                        this.convertChildrenShouldExternal(ruleItem, bool);
                    }
                    // Caso o RULEITEM seja um unico item
                    else {

                        if (isNull(bool['should']))
                            bool['should'] = [];

                        if (isNull(boolShould))
                            boolShould = new ESBuilderQueryBool();

                        let filter = this.convertCondition(ruleItem);
                        let condition = (ruleItem.condition == ESBuilderRulesConditions.or) ? 'or' : 'and';

                        if (previousCondition != condition) {
                            previousCondition = (condition == 'or') ? 'and' : condition;
                            bool.should.push({ 'bool': boolShould });
                            boolShould = new ESBuilderQueryBool();
                        }

                        if (isNull(boolShould[filter.needed]))
                            boolShould[filter.needed] = [];

                        boolShould[filter.needed].push(filter.filter);
                    }
                }
                // Caso seja um RULEITEM com condição AND normal, é verificado se o mesmo é um grupo, se for, e tiver algum ITEM FILHO com
                // condição OR um item SHOULD e colocado dentro do MUST/MUST_NOT
                else {
                    // Caso o RULEITEM seja um grupo
                    if (this.isRuleGroup(ruleItem)) {
                        this.convertChildrenShouldInternal(ruleItem, bool);

                    }
                    // Caso o RULEITEM seja um unico item
                    else {
                        let filter = this.convertCondition(ruleItem);

                        if (isNull(bool[filter.needed]))
                            bool[filter.needed] = [];

                        bool[filter.needed].push(filter.filter);
                    }
                }
            }
        });

        if (isShouldFatherCondition && (!isNull(boolShould) && (!isNull(boolShould.must) || !isNull(boolShould.must_not) || !isNull(boolShould.should))))
            bool.should.push({ 'bool': boolShould });

        bool = this.updateIntegrated(data, bool);

        let query: ESBuilderQuery = new ESBuilderQuery();

        if (data.type == 'filtered')
            query.query[data.type]['filter'].bool = bool;

        if (data.type == 'bool')
            query.query[data.type] = bool;

        return query;
    }

    private static updateIntegrated(data: ESBuilderData, bool: ESBuilderQueryBool): ESBuilderQueryBool {
        var resetMust = false;
        var resetMustNot = false;

        if (data.includeIntegratedRegister == ESBFiltroStatusRegistroLista.naoUtilizado) {
            resetMust = true;

            if (isNullOrEmpty(bool.must_not)) {
                bool['must_not'] = [{ "exists": { "field": "_integrado" } }];
            } else {
                bool.must_not.push({ exists: { field: "_integrado" } });
            }
        }

        if (data.includeIntegratedRegister == ESBFiltroStatusRegistroLista.utilizado) {
            resetMustNot = true;

            if (isNullOrEmpty(bool.must)) {
                bool['must'] = [{ "exists": { "field": "_integrado" } }];
            } else {
                bool.must.push({ exists: { field: "_integrado" } });
            }
        }

        if (data.includeIntegratedRegister == ESBFiltroStatusRegistroLista.total) {
            resetMust = true;
            resetMustNot = true;
        }

        if (resetMustNot && !isNullOrEmpty(bool.must_not)) {
            bool.must_not = bool.must_not.filter((f: any) => {
                if (f.exists != undefined)
                    return (f.exists.field != "_integrado");

                return true;
            });
        }

        if (resetMust && !isNullOrEmpty(bool.must)) {
            bool.must = bool.must.filter((f: any) => {
                if (f.exists != undefined)
                    return (f.exists.field != "_integrado");

                return true;
            });
        }

        return bool;
    }

    //#region [ Métodos auxiliares de conversão ]

    /** 
     * Conversão da REGRA em FILTER do ElasticSearch
     * @param {ESBuilderRules} ruleItem Item de regra
     */
    private static convertCondition(ruleItem: ESBuilderRules) {

        // Conversão da condição em filter do ElasticSearch
        let fieldType = ESBuilderFilterType[ruleItem.fieldType];
        let filterType = (ruleItem.fieldType == 'Nested')
            ? fieldType.find((f: any) => (f.nestedType == ruleItem.fieldNestedType && f.filterType == ruleItem.filterType))
            : fieldType.find((f: any) => (f.filterType == ruleItem.filterType));

        let filter = (ruleItem.filterDataType != 3)
            ? this.convertFilter(filterType, ruleItem)
            : this.convertFilterScript(filterType, ruleItem);

        // Verifica se adiciona o filtro ao must ou must_not
        //let needed = (filterType.negation) ? "must_not" : "must";
        let needed = (ruleItem.reverseNegation) ? ((filterType.negation) ? "must" : "must_not") : ((filterType.negation) ? "must_not" : "must");

        return { 'filter': filter, 'needed': needed };
    }

    private static convertChildrenShouldExternal(ruleItem: ESBuilderRules, bool: ESBuilderQueryBool) {

        if (isNull(bool['should']))
            bool['should'] = [];

        let boolShould: ESBuilderQueryBool = new ESBuilderQueryBool();
        let previousChildrenCondition: string = 'and';

        ruleItem.rules.forEach((ruleChildrenItem: ESBuilderRules) => {

            if (isObjectEmpty(ruleChildrenItem.filterValues))
                return;

            // Conversão da condição em filter do ElasticSearch
            let filter = this.convertCondition(ruleChildrenItem);
            let condition = (ruleChildrenItem.condition == ESBuilderRulesConditions.or) ? 'or' : 'and';

            if (previousChildrenCondition != condition) {
                previousChildrenCondition = condition;
                bool.should.push({ 'bool': boolShould });
                boolShould = new ESBuilderQueryBool();
            }

            if (isNull(boolShould[filter.needed]))
                boolShould[filter.needed] = [];

            boolShould[filter.needed].push(filter.filter)
        });

        bool.should.push({ 'bool': boolShould });
    }

    /**
     * Converte os filhos de um grupo para o formato query, verificando se os mesmos devem ser adicionados como
     * condições de MUST/MUST_NOT ou se eles são do tipo SHOULD
     * @param {ESBuilderRules} ruleItem Item de regra com seus filhos
     * @param {ESBuilderQueryBool} bool Item BOOL da query
     */
    private static convertChildrenShouldInternal(ruleItem: ESBuilderRules, bool: ESBuilderQueryBool) {

        let should: Array<any> = [];
        let boolShould: ESBuilderQueryBool = new ESBuilderQueryBool();
        let previousChildrenCondition: string = 'and';
        let isChildrenShouldCondition = ruleItem.rules.some((s: ESBuilderRules) => (s.condition == ESBuilderRulesConditions.or));

        ruleItem.rules.forEach((ruleChildrenItem: ESBuilderRules, index: number) => {

            if (isObjectEmpty(ruleChildrenItem.filterValues))
                return;

            // Conversão da condição em filter do ElasticSearch
            let filter = this.convertCondition(ruleChildrenItem);
            let condition = (ruleChildrenItem.condition == ESBuilderRulesConditions.or) ? 'or' : 'and';

            // Se os FILHOS tiverem condições com OR
            if (isChildrenShouldCondition) {
                if (previousChildrenCondition != condition) {
                    previousChildrenCondition = (condition == 'or') ? 'and' : condition;
                    should.push({ 'bool': boolShould });
                    boolShould = new ESBuilderQueryBool();
                }

                if (isNull(boolShould[filter.needed]))
                    boolShould[filter.needed] = [];

                boolShould[filter.needed].push(filter.filter);

                if (index == (ruleItem.rules.length - 1))
                    should.push({ 'bool': boolShould });

            }
            // Se os FILHOS NÃO tiverem condições com OR
            else {
                // Adiciona o nó de must / must_not dentro do bool da query caso não tenha
                if (isNull(bool[filter.needed]))
                    bool[filter.needed] = [];

                bool[filter.needed].push(filter.filter);

            }
        });

        if (should.length > 0) {
            // Adiciona o nó de must / must_not dentro do bool da query caso não tenha
            if (isNull(bool['must']))
                bool['must'] = [];

            bool['must'].push({ 'bool': { 'should': should } });
        }

        return bool;
    }

    //#endregion

    //#region [ Métodos de Convert Filter ]

    private static convertFilter(filterType: any, ruleItem: ESBuilderRules): any {
        switch (filterType.content) {
            case 'query_string':
                return this.convertFilterQueryString(filterType, ruleItem);
            case 'terms':
                return this.convertFilterTerms(filterType, ruleItem);
            case 'exists':
                return this.convertFilterFieldQuery(filterType, ruleItem);
            case 'wildcard':
                return this.convertFilterWildcard(filterType, ruleItem);
            case 'script':
                return this.convertFilterEmptyQuery(filterType, ruleItem);
            case 'regexp':
                return this.convertFilterRegex(filterType, ruleItem);
            case 'term':
                return this.convertFilterTerm(filterType, ruleItem);
            case 'nested':
                return this.convertFilterNested(filterType, ruleItem);
            default:
                return this.convertFilterBase(filterType, ruleItem);
        }
    }

    private static convertFilterBase(filterType: any, ruleItem: ESBuilderRules): any {
        let property = (filterType.keyword) ? `${esbFormatFieldName(ruleItem.field)}.keyword` : esbFormatFieldName(ruleItem.field);

        let obj = { ...ruleItem.filterValues };

        Object.keys(obj).forEach((item: string) => {
            if (obj[item] instanceof Date)
                obj[item] = dayjs(ruleItem.filterValues[item]).format("YYYY-MM-DD HH:mm:ss"); //.format("DD/MM/YYYY HH:mm:ss");
        });


        return { [filterType.content]: { [property]: obj } };
    }

    private static convertFilterQueryString(filterType: any, ruleItem: ESBuilderRules): any {
        let param = (ruleItem.filterValues.query.indexOf("*") != -1) ? ruleItem.filterValues.query : `*${ruleItem.filterValues.query}*`;

        return { [filterType.content]: { 'default_field': esbFormatFieldName(ruleItem.field), 'query': param } };
    }

    private static convertFilterTerms(filterType: any, ruleItem: ESBuilderRules): any {

        if (filterType.content == "terms" && ruleItem.fieldType == "Lista" && ruleItem.filterValues) {
            let property = (filterType.keyword) ? `${esbFormatFieldName(ruleItem.field)}.keyword` : esbFormatFieldName(ruleItem.field);
            //let values = ruleItem.filterValues.toString().split(",").map((f: any) => (f.trim()));
            let values = ruleItem.filterValues.map(m => m.value.trim());
            return { [filterType.content]: { [property]: values } };
        }

        if (filterType.content == "terms" && ruleItem.filterValues.query) {
            let values = ruleItem.filterValues.query.toString().toLowerCase().split(",").map((f: any) => (f.trim()));
            return { [filterType.content]: { [esbFormatFieldName(ruleItem.field)]: values } };
        }

        return this.convertFilterBase(filterType, ruleItem);
    }

    private static convertFilterWildcard(filterType: any, ruleItem: ESBuilderRules): any {
        return { [filterType.content]: { [esbFormatFieldName(ruleItem.field)]: '*' } };
    }

    private static convertFilterFieldQuery(filterType: any, ruleItem: ESBuilderRules): any {
        return { [filterType.content]: { 'field': esbFormatFieldName(ruleItem.field) } };
    }

    private static convertFilterEmptyQuery(filterType: any, ruleItem: ESBuilderRules): any {
        return {
            "script": {
                "script": {
                    "inline": "doc['" + esbFormatFieldName(ruleItem.field) + "'].empty",
                    "lang": "painless"
                }
            }
        };
    }

    private static convertFilterRegex(filterType: any, ruleItem: ESBuilderRules): any {
        let property = (filterType.keyword) ? `${esbFormatFieldName(ruleItem.field)}.keyword` : esbFormatFieldName(ruleItem.field);
        return { [filterType.content]: { [property]: ruleItem.filterValues.query } };
    }

    private static convertFilterTerm(filterType: any, ruleItem: ESBuilderRules): any {
        if (ruleItem.fieldType.toLowerCase() == "boolean" && ruleItem.filterValues.query != undefined) {
            return { [filterType.content]: { [esbFormatFieldName(ruleItem.field)]: (ruleItem.filterValues.query === true) } };

        } else if (filterType.keyword && ruleItem.fieldType.toLowerCase() == "texto") {
            let property = (filterType.keyword) ? `${esbFormatFieldName(ruleItem.field)}.keyword` : esbFormatFieldName(ruleItem.field);
            return { [filterType.content]: { [property]: ruleItem.filterValues.query } };
        }

        return this.convertFilterBase(filterType, ruleItem);
    }

    private static convertFilterNested(filterType: any, ruleItem: ESBuilderRules): any {

        let property = esbFormatFieldName(ruleItem.field);
        //let keyword = false;

        let converted = {
            "nested": {
                "path": property.split('.').slice(0, -1).join('.'),
                "query": { "bool": {} }
            }
        };

        switch (ruleItem.nestedContent) {
            case 'match':
                if (ruleItem.nestedList) {
                    converted.nested.query.bool['should'] = [];
                    ruleItem.filterValues.forEach((prm: any) => {
                        let filter: any = { [ruleItem.nestedContent]: { [((filterType.keyword) ? `${property}.keyword` : property)]: prm.value } };
                        converted.nested.query.bool['should'].push({ "bool": { "must": [filter] } });
                    });
                } else {
                    let filter: any = { [ruleItem.nestedContent]: { [((filterType.keyword) ? `${property}.keyword` : property)]: ruleItem.filterValues } };
                    converted.nested.query.bool['must'] = [filter];
                }

                break;
            case 'exists':
                converted.nested.query.bool['must'] = [{ [ruleItem.nestedContent]: { 'field': property } }];
                break;
            case 'range':
                let filter: any = { [ruleItem.nestedContent]: { [((filterType.keyword) ? `${property}.keyword` : property)]: ruleItem.filterValues } };
                converted.nested.query.bool['must'] = [filter];
                break;

        }
        return converted;


        // if (ruleItem.fieldType.toLowerCase() == "boolean" && ruleItem.filterValues.query != undefined) {
        //     return { [filterType.content]: { [esbFormatFieldName(ruleItem.field)]: (ruleItem.filterValues.query === true) } };

        // } else if (filterType.keyword && ruleItem.fieldType.toLowerCase() == "texto") {
        //     let property = (filterType.keyword) ? `${esbFormatFieldName(ruleItem.field)}.keyword` : esbFormatFieldName(ruleItem.field);
        //     return { [filterType.content]: { [property]: ruleItem.filterValues.query } };
        // }

        // return this.convertFilterBase(filterType, ruleItem);
    }



    //#endregion

    //#region [ Métodos de Convert Filter Script ]

    private static convertFilterScript(filterType: any, ruleItem: ESBuilderRules): any {
        let script = '';
        let getMillis = (ruleItem.fieldType == 'Data') ? '.getMillis()' : '';
        let property = esbFormatFieldName(ruleItem.field);
        let gte = (isNullOrEmpty(ruleItem.filterValues.gte)) ? null : esbFormatFieldName(ruleItem.filterValues.gte);
        let lte = (isNullOrEmpty(ruleItem.filterValues.lte)) ? null : esbFormatFieldName(ruleItem.filterValues.lte);

        if (filterType.content == 'range') {
            switch (filterType.filterType) {
                case 'entre':
                    script = `(!doc['${property}'].empty && !doc['${gte}'].empty && !doc['${lte}'].empty) && doc['${property}'].value${getMillis} >= doc['${gte}'].value${getMillis} && doc['${esbFormatFieldName(ruleItem.field)}'].value${getMillis} <= doc['${lte}'].value${getMillis}`;
                    break;
                case 'maior_que':
                    script = `(!doc['${property}'].empty && !doc['${esbFormatFieldName(ruleItem.filterValues.gt)}'].empty) && doc['${property}'].value${getMillis} > doc['${esbFormatFieldName(ruleItem.filterValues.gt)}'].value${getMillis}`;
                    break;
                case 'menor_que':
                    script = `(!doc['${property}'].empty && !doc['${esbFormatFieldName(ruleItem.filterValues.lt)}'].empty) && doc['${property}'].value${getMillis} < doc['${esbFormatFieldName(ruleItem.filterValues.lt)}'].value${getMillis}`;
                    break;
                case 'maior_ou_igual':
                    script = `(!doc['${property}'].empty && !doc['${gte}'].empty) && doc['${property}'].value${getMillis} >= doc['${gte}'].value${getMillis}`;
                    break;
                case 'menor_ou_igual':
                    script = `(!doc['${property}'].empty && !doc['${lte}'].empty) && doc['${property}'].value${getMillis} <= doc['${lte}'].value${getMillis}`;
                    break;
            }
            return { "script": { "script": script } };
        }

        if (filterType.content == 'match') {

            switch (filterType.filterType) {
                case 'igual':
                    script = `(!doc['${property}'].empty && !doc['${esbFormatFieldName(ruleItem.filterValues.query)}'].empty) && doc['${property}'].value${getMillis} == doc['${esbFormatFieldName(ruleItem.filterValues.query)}'].value${getMillis}`;
                    break;
                case 'diferente':
                    // Sinal é igual pois a condição sera colocada no must not
                    script = `(!doc['${property}'].empty && !doc['${esbFormatFieldName(ruleItem.filterValues.query)}'].empty) && doc['${property}'].value${getMillis} == doc['${esbFormatFieldName(ruleItem.filterValues.query)}'].value${getMillis}`;
                    break;
            }
            return { "script": { "script": script } };
        }
        return;
    }

    //#endregion

    //#region [ Métodos auxiliares ]

    /** 
    * Verifica se o item REGRA é na verdade um GRUPO DE REGRAS
    * @param {ESBuilderRules} ruleItem Item de regra
    */
    private static isRuleGroup(ruleItem: ESBuilderRules) {
        return (isNullOrEmpty(ruleItem.field) && !isNull(ruleItem.rules) && ruleItem.rules.length > 0);
    }

    //#endregion 
}




