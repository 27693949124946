import { lPad } from "./lPad";
import * as dayjs from 'dayjs';

export function generateHours(min: number = 1) {
    let hours: Array<string> = [];

    for (let i = 0; i < 23; i = i + min)
        hours.push((i < 10) ? lPad(i.toString(), 2) : i.toString());

    return hours;
}

export function generateHoursNew(minMinute: number = 1): Array<string> {
    // let hours: Array<string> = [];
    // let minutes: Array<string> = [];

    // for (let i = 0; i < 23; i = i + 1)
    //     hours.push((i < 10) ? lPad(i.toString(), 2) : i.toString());

    // for (let i = 0; i < 59; i = i + minMinute)
    //     minutes.push((i < 10) ? lPad(i.toString(), 2) : i.toString());

    // let hourly: Array<string> = [];
    // hours.forEach((h: string) => minutes.forEach((m: string) => hourly.push(`${h}:${m}`)));

    // return hourly

    let items: Array<string> = []
    let currentDate = dayjs(Date.now());
    let finishDate = dayjs(Date.now()).add(1, 'day');
    let on = true;

    while (on == true) {
        items.push(currentDate.format('HH:mm'))
        currentDate = currentDate.add(minMinute, 'minutes');

        if (currentDate >= finishDate)
            on = false;
    }

    return items;
}