import { Senha } from './../model/senha';
import { Router, ActivatedRoute } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { AutenticacaoService } from "../../common/service/autenticacao.service";
import { UsuarioService } from "../../common/service/usuario.service";
import { CustomAlertService } from "../../common/service/custom-alert.service";
import { PermissoesUsuario } from "../../common/model/permissoes-usuario";
import { PermissaoDefault } from "../../common/model/permissao-default";
import { MatDialog } from "@angular/material";
import { environment } from "../../../../environments/environment";
import { ConfiguracaoService } from "../../common/service/configuracao.service";
import { WINDOW } from "../../../common/providers/window.provider";
import {mostrarSenha,impedirEspaco, onKey } from "../../common/utils/validacaoSenha";
import { Injectable, Inject } from '@angular/core';
import { ValidacaoSenhaModel } from '../../common/model/validacao-senha';

@Component({
	selector: "app-alterar-senha-expirada",
	templateUrl: "./alterar-senha-expirada.component.html",
	styleUrls: ["./alterar-senha-expirada.component.scss"],
	providers: [
		{ useClass: CustomAlertService, provide: CustomAlertService },
		{ useClass: UsuarioService, provide: UsuarioService },
		{ useClass: ConfiguracaoService, provide: ConfiguracaoService }
	]
})
export class AlterarSenhaExpiradaComponent implements OnInit {
	public senha: Senha;
	public filtro: ValidacaoSenhaModel;
	public AppConfig: any;
	public mostrarForm: boolean = false;
	public loginOktaAtivo: boolean = false;
	logo: string = 'talkmaestro';	

	constructor(
		private autenticacaoService: AutenticacaoService,
		private customAlertService: CustomAlertService,
		private usuarioService: UsuarioService,
		private configService: ConfiguracaoService,
		private router: Router,
		public dialog: MatDialog,
		private route: ActivatedRoute,
		@Inject(WINDOW) private window: Window
	) {
		this.senha = new Senha();
		this.senha.senhaAtual = '';
		this.filtro = new ValidacaoSenhaModel();
	}

	ngOnInit() {
		
		this.AppConfig = environment;
		this.route.queryParams.subscribe(parametros =>{
			this.senha.id = parametros['id'];
		})

        this.customAlertService.show(
            "telaPadrao.atencao",
            "login.usuarioSenhaExpirada",
            "error"
        );

	}	

	salvarSenha() {
		this.usuarioService.alterarSenha(this.senha).subscribe(s=>
			{
			  if(s.isValid){
				this.customAlertService.show(
				  "Login",
				  "login.senhaAlterada",
				  "success"
				).then(() => {
					this.router.navigate(['/Login']);
				  });
			  }
			  else{

                if (s.errors.lenght !== null)
				{
					this.customAlertService.show(
						"telaPadrao.atencao",
						s.errors[0].arguments[0] +"."+ s.errors[0].message,
						"error"
					  );
					
				}
				else{
					this.customAlertService.show(
					"telaPadrao.atencao",
					"telaPadrao.alteracaoErro",
					"error"
					);
				}
			  }
			}
		  );
	}

	validaRegraSenha(event: any): void
	{
		this.filtro = onKey(this.filtro,this.senha.senha);
	}

	get mostrarSenha()
	{
		return mostrarSenha;
	}

	get impedirEspaco()
	{
		return impedirEspaco;
	}

}
