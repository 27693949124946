import * as validateJS from "validate.js";
import { isNullOrEmpty } from "../utils";
import { FiltroStatusRegistroLista } from "./lista";
import { SituacaoExecucao, EstrategiaAcaoCampanhaTipo } from "./situacao-execucao";
import { FiltroQuery } from "./filtro-query";
import { AgendamentoModel } from "../../estrategia/models/agendamento.model";
import { FiltroQueryEstrategia } from "./filtro-query-estrategia";

//import * as validateJS from "validate.js";

export class EstrategiaRefactor {
	listaEstrategiaId: number;
	nome: string;
	estrategiaAcaoCampanhaTipoId: EstrategiaAcaoCampanhaTipo;
	listaId: number;
	displayNomeLista: string;
	ativo: boolean;

	filtro: string;
	filtroRegras: string;
	//filtroQueryId: number | null;
	filtroQueryEstrategias: Array<FiltroQueryEstrategia>;
	//filtroQuery: FiltroQuery;

	providerId: number;
	listaTemplateExportacaoId: number | null;
	campanhaId: string;
	campanhaNome: string;
	codigoIntegracao: string;

	envioImediato: boolean;
	recorrente: boolean;
	intervalo: number;
	frequencia: string;
	horaInicio: any;
	horaTermino: any;
	dataInicio: Date;
	dataTermino: Date;
	execucaoUnica: boolean = true;
	diretorioDestino: string;
	pendenteExecucao: boolean;
	agendado: boolean;
	forcarExecucao: boolean;

	dataCadastro: Date;
	usuarioCadastro: string;
	nomeUsuarioCadastro: string;

	dataAlteracao: Date;
	proximaExecucao: Date;
	ultimaExecucao: Date;
	usuarioAlteracao: Date;
	porcentagemIntegracao: number;
	quantidadeRegistros: number;
	diaSemana: string;
	expressaoRecorrencia: string;

	reenviarDistribuido: FiltroStatusRegistroLista;
	marcarDistribuido: boolean = true;
	listaEstrategiaPrioridade: any[];
	listaEstrategiaTipoId: number;
	limitarExportacao:boolean = false;
	quantidadeMaximaExportacao: number;
	quantidadeMaximaExportacaoPct: number;
	medidaMaximaExportacaoPct: boolean = true;
	quantidadeLote: number;

	campoCard: string;
	operacaoCampoCard: number;
	campoAgrupadorSomaUnicosCard: string;
	formatacaoCard: string;

	notificarZerada: boolean;

	intervaloHoraInicio: any;
	intervaloHoraTermino: any;
	situacaoExecucao: SituacaoExecucao;

	filtroMigrado: boolean;
	enviarEstrategiaIdExportacao: boolean;


	agendamento: AgendamentoModel = new AgendamentoModel();

	//status: number; 	//todo: não tem status
	//filtroAlias: string;

	//#region [ Campos que não existem no banco ]

	// Usado nas estrategias de remoção
	estrategiaBaseId: number;

	// Usados para criar o vinculo com a regua estrategia
	reguaId: number;
	reguaEstrategiaId: number;
	provedorNome:string;
	templateExportacaoNome:string;


	// Usados para criar o vinculo com o fluxo processo
	fluxoId: number;
	fluxoProcessoId: number;

	// Usado para definir o mínino de minutos que tem no agendamento da estratégia
	estrategiaAgendamentoMinimoMinutos: number = 10;

	// Utilizado no vinculo do filtro padrão
	filtroAlterado: boolean;
	hashFiltroAntigo: string;

	//#endregion

	//#region [ GET/SET - ComDataFim ]

	_comDataFim: boolean;

	public get comDataFim(): boolean {
		return this._comDataFim != undefined ? this._comDataFim : this.dataTermino != undefined;
	}

	public set comDataFim(valor: boolean) {
		if (!valor) {
			this.dataTermino = undefined;
			this.horaTermino = undefined;
		}
		this._comDataFim = valor;
	}

	//#endregion

	//#region [ GET/SET - ExecucaoRecorrente ]

	public get execucaoRecorrente(): boolean {
		return !this.execucaoUnica;
	}

	public set execucaoRecorrente(valor: boolean) {
		this.execucaoUnica = !valor;
	}

	//#endregion

	constructor() {
		this.quantidadeLote = 50000;
		this.limitarExportacao = false;
		this.situacaoExecucao = SituacaoExecucao.play;
		this.validacaoCustomizadas();
	}

	static fromRaw(raw: any) {
		let estrategia = new EstrategiaRefactor();
		Object.assign(estrategia, raw);
		return estrategia;
	}
	aplicarRegraExportacaoLimitada()
	{
		this.quantidadeMaximaExportacaoPct = 100;
		if (this.limitarExportacao){
			this.medidaMaximaExportacaoPct = false;
		}
		else{
			this.quantidadeMaximaExportacao = 0;
			this.medidaMaximaExportacaoPct = true;
		}
	}

	// #region [ Validação ]

	validar(validarCampoCampanha: boolean = false) {
		let regrasPadrao: any = {
			'nome': { 'presence': { 'allowEmpty': false, 'message': '^campoObrigatorio' } },
			'estrategiaAcaoCampanhaTipoId': { 'presence': { 'allowEmpty': false, 'message': '^campoObrigatorio' } },
			'listaId': { 'presence': { 'allowEmpty': false, 'message': '^campoObrigatorio' } },
			'providerId': { 'presence': { 'allowEmpty': false, 'message': '^campoObrigatorio' } },
			'listaTemplateExportacaoId': { 'presence': { 'allowEmpty': false, 'message': '^campoObrigatorio' } }
		};
		if (this.limitarExportacao)
			regrasPadrao.quantidadeMaximaExportacao = { numericality:{greaterThan: 0,message: '^invalido'}};

		let regrasCampoCampanha = this.regrasValidacaoCampoCampanha(validarCampoCampanha);
		let regrasAgendamento = this.regrasValidacaoAgendamento();
		let regrasEstrategiaRemocao = this.regrasEstrategiaRemocao();

		let regras = {
			...regrasPadrao,
			...regrasCampoCampanha,
			...regrasAgendamento,
			...regrasEstrategiaRemocao
		};

		return validateJS(this, regras);
	}

	private validacaoCustomizadas() {
		validateJS.validators.dataMenorQue = function (value, options, key, attributes) {
			if (value >= attributes[options.campo]) return "dataMaior";
		};

		validateJS.validators.dataMaiorQue = function (value, options, key, attributes) {
			if (value <= attributes[options.campo]) return "dataMenor";
		};
	}

	private regrasValidacaoCampoCampanha(validarCampoCampanha) {
		let regras: any = {};

		if (validarCampoCampanha) {
			regras.campanhaId = { 'presence': { 'allowEmpty': false, 'message': '^campoObrigatorio' } };
		}

		return regras;
	}

	private regrasValidacaoAgendamento(): any {

		let regras: any = {};

		if (this.recorrente) {

			if (!this.execucaoRecorrente) {
				regras.dataInicio = { 'presence': { 'allowEmpty': false, 'message': '^campoObrigatorio' } };
				regras.horaInicio = { 'presence': { 'allowEmpty': false, 'message': '^campoObrigatorio' } };
			} else {
				regras.frequencia = { 'presence': { 'allowEmpty': false, 'message': '^campoObrigatorio' } };
				regras.dataInicio = { 'presence': { 'allowEmpty': false, 'message': '^campoObrigatorio' } };

				if (this.frequencia == '1' || this.frequencia == '2') {
					let greaterThanOrEqualTo = ((this.frequencia == '1') ? this.estrategiaAgendamentoMinimoMinutos : 1);

					regras.intervalo = {
						'presence': { 'allowEmpty': false, 'message': '^campoObrigatorio' },
						'numericality': { 'onlyInteger': true, 'greaterThanOrEqualTo': greaterThanOrEqualTo, 'message': '^menorque' }
					};

					if (!isNullOrEmpty(this.intervaloHoraInicio) || !isNullOrEmpty(this.intervaloHoraTermino)) {
						regras.intervaloHoraInicio = { 'presence': { 'allowEmpty': false, 'message': '^campoObrigatorio' } };
						regras.intervaloHoraTermino = { 'presence': { 'allowEmpty': false, 'message': '^campoObrigatorio' } };
					}

				} else {
					regras.horaInicio = { 'presence': { 'allowEmpty': false, 'message': '^campoObrigatorio' } };
				}

				if (this.comDataFim) {
					regras.dataTermino = {
						'presence': { 'allowEmpty': false, 'message': '^campoObrigatorio' },
						'dataMaiorQue': { 'campo': 'dataInicio', 'message': '^dataTerminoInvalida' }
					};
				}
			}
		}

		return regras;
	}

	private regrasEstrategiaRemocao(): any {
		let regras: any = {};

		if (this.estrategiaAcaoCampanhaTipoId == EstrategiaAcaoCampanhaTipo.RemocaoCampanhaEpbx) {
			regras.estrategiaBaseId = { 'presence': { 'allowEmpty': false, 'message': '^campoObrigatorio' } };
		}

		return regras;
	}

	//#endregion
}
