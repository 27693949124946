import { Component, Inject, ViewChild, OnDestroy, DebugElement } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Subscription } from "rxjs";
import { FluxoService } from "../../common/service/fluxo.service";
import { isNullOrEmpty } from '../../common/utils';
import { ESBuilderData } from '../../esbuilder/models'
import { PreviewDadosEstrategiaComponent } from "../../estrategia/form-estrategia/preview-dados-estrategia/preview-dados-estrategia.component";

@Component({
    selector: "app-esbuilder-modal-filter-preview",
    templateUrl: "./esbuilder-modal-filter-preview.component.html",
    styleUrls: ["./esbuilder-modal-filter-preview.component.scss"],
    providers: [
        { useClass: FluxoService, provide: FluxoService }
    ]
})
export class EsBuilderModalFilterPreviewComponent {

    activeStandardFilter: boolean = true;
    enableConditionOr: boolean = true;
    appendToSelect: string = "body";
    readOnly: boolean = false;

    listaId: number;
    query: any = JSON.parse('{"size":10,"_source":{"includes":[]},"query":{"bool":{"must":[],"must_not":[{"exists":{"field":"_integrado"}}]}}}');
    dataRules: ESBuilderData = new ESBuilderData();
    quantityList: number;
    finishedLoading: boolean = false;

    constructor(
        private ref: MatDialogRef<EsBuilderModalFilterPreviewComponent>,
        private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA)
        public data: { listaId: number, activeStandardFilter: boolean, enableConditionOr: boolean, appendToSelect: string, readOnly: boolean, dataRules: string, query: string }
    ) {
        this.listaId = this.data.listaId;

        if (!isNullOrEmpty(this.data.activeStandardFilter))
            this.activeStandardFilter = this.data.activeStandardFilter;

        if (!isNullOrEmpty(this.data.enableConditionOr))
            this.enableConditionOr = this.data.enableConditionOr;

        if (!isNullOrEmpty(this.data.appendToSelect))
            this.appendToSelect = this.data.appendToSelect;

        if (!isNullOrEmpty(this.data.readOnly))
            this.readOnly = this.data.readOnly;

        if (!isNullOrEmpty(this.data.dataRules))
            this.dataRules = JSON.parse(this.data.dataRules);

        if (!isNullOrEmpty(this.data.query))
            this.query = this.data.query;

        this.finishedLoading = true;
    }

    updateQuery(event: any) {
        if (!isNullOrEmpty(event.query))
            this.query = event.query;

        if (!isNullOrEmpty(event.dataRules))
            this.dataRules = event.dataRules;
    }

    quantityListChanged(event: any) {
        this.quantityList = event.quantidadeEstrategia;
    }

    apply() {
        this.ref.close({ 'query': this.query, 'dataRules': this.dataRules, 'quantityList': this.quantityList });
    }

    close() {
        this.ref.close();
    }


    // ngOnDestroy() {
    //     if (this.subFluxo)
    //         this.subFluxo.unsubscribe();
    // }
}
